import { storesInfoFactory } from './stores';
import { storePagesInfoFactory } from './store-pages';
import { productsInfoFactory } from './products';

const info = {
  storesInfoFactory,
  storePagesInfoFactory,
  productsInfoFactory
};

export { info as default };