
  export default {
    props: ['error'],
    data() {
      return {
        //
        errorPage: null,
      };
    },
    async created() {
      let pageData = {};
      try {
        if (!this.$store.getters.storeInformation) {
          throw this.error;
        }
        const { storeUrl } = this.$store.getters.storeInformation;
        const { statusCode } = this.error;
        pageData.tags = (
          await this.$api.stores.getPageHeadTags(storeUrl, statusCode, '', {
            pageRequest: this.error.pageRequest,
          })
        ).data;
        pageData.page = (
          await this.$api.stores.getStoreSlugPage({
            storeUrl,
            urlSlug: statusCode,
          })
        ).data;

        if (this.error.statusText)
          pageData.page.push({
            id: '5d49ec99a2bf0507716a81d5' + Date.now(),
            name: 'Heading2',
            props: {
              container: { class: 'text-center', keyId: 9252 },
              heading: { class: 'text-danger h6', text: this.error.statusText, keyId: 1496 },
              keyId: 9640,
            },
            isEnabled: true,
            showWhenQuery: false,
            hideWhenQuery: false,
            hideWhenMoreThanOneQuery: false,
          });
        this.errorPage = pageData;
      } catch (e) {
        const title =
          (e.status === 404 || e.toString().match('404') ? 'Page Not Found' : 'Error') +
          ((this.error.pageRequest && ` | ${this.error.pageRequest}`) || '');
        if (!pageData?.tags?.link) {
          pageData.tags = {
            ...(pageData.tags || {}),
            link: [{
              description: "fontawesome.all.css",
              extension: "css",
              external: true,
              href: "https://fa.mpowerpromo.com/css/all.css",
              rel: "stylesheet"
            }, {
              description: "Bootstrap Theme",
              href: "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css",
              isBootstrap: true,
              rel: "stylesheet",
              tagType: "link"
            }]
          };
        }
        
        this.errorPage = {
          ...pageData,
          page: [
            {
              name: 'Error1',
              props: {
                error: {
                  message: e.message || e.data || e.statusText,
                  statusCode: e.status,
                },
              },
            },
            {
              id: '5d49ec99a2bf0507716a81d5' + Date.now(),
              name: 'Heading2',
              props: {
                container: { class: 'text-center', keyId: 9252 },
                heading: { class: 'text-danger h6', text: this.error.statusText, keyId: 1496 },
                keyId: 9640,
              },
              isEnabled: true,
              showWhenQuery: false,
              hideWhenQuery: false,
              hideWhenMoreThanOneQuery: false,
            },
          ],
          tags: {
            ...(pageData.tags || {}),
            title,
          },
        };
      }
    },
    components: {
      page: () => import('@/components/pageGenerator'),
    },
  };
