export async function loadScripts(list) {
  if (!process.client) return;
  let result;
  for (let i = 0; i < list.length; i++) {
    result = await loadScript(list[i]);
  }
  return result;
}

export function loadScript(item) {
  if (!window.loaded) window.loaded = {};
  if (!window.loaded[item.src]) {
    window.loaded[item.src] = 1;
  } else {
    return -1;
  }
  return new Promise((resolve) => {
    const script = document.createElement(item.type);
    if (item.type === 'link') {
      script.setAttribute('rel', 'stylesheet');
      script.setAttribute('type', 'text/css');
      script.setAttribute('href', item.src);
    } else {
      script.setAttribute(item.type === 'script' ? 'src' : 'href', item.src);
    }

    script.onload = function() {
      resolve(1);
      window.loaded[item.src] = 2;
    };
    document.body.appendChild(script);
    if (item.type !== 'script') {
      resolve(1);
    }
  });
}
