export default function({ store, redirect, route: { path, fullPath, query } }) {
  if (store.getters.storeInformation) {
    const { requireSignIn } = store.getters.storeInformation;
    const isAuthenticated = store.getters['auth/isAuthenticated'];
    const allowedPaths = [
      '/createaccount',
      '/signin',
      '/forgotpassword',
      '/resetpassword',
      /^\/sso|printinvoice|payments\/*/,
    ];
    const isAllowedPath = allowedPaths.some((allowedPath) => {
      return allowedPath instanceof RegExp ? allowedPath.test(path) : allowedPath === path;
    });
    if (requireSignIn && !isAuthenticated && !isAllowedPath) {
      let previousPath = '';
      if (path === '/account' || path === '/signout') {
        previousPath = `?redirect=${encodeURIComponent(query?.redirect || '/account')}`;
      } else {
        previousPath = path ? `?redirect=${encodeURIComponent(path)}` : '';
      }
      return redirect(`/signin${previousPath}`);
    }

    if (path === '/signin' && isAuthenticated) {
      const redirectQuery = new URLSearchParams(fullPath.split('?')[1]).get('redirect');

      if (redirectQuery === '/account' || redirectQuery === '/signout') {
        return redirect('/account');
      } else {
        return redirect(redirectQuery || '/');
      }
    }
  }
}
