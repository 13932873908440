const axios = require('axios');

const VERSION = require('./../../package.json').version;
const HTTP = axios.create({
  baseURL: process.env.API_URL || 'http://localhost:3001',
  headers: {
    version: VERSION,
    apitoken: process.env.API_TOKEN || 'qweQj4giRJSdMNzB8g1XIa6t3YtRIHPH',
    [`User-Agent`]: `Mpower-store`,
  },
});

export default async function (req, res, next) {
  let host = process.env.STORE_URL || req.headers.host;
  const hasId = host.split('.').find((e) => e.toString().match(/^[0-9a-fA-F]{24}$/));
  if (hasId) host = req.headers['X-Real-Host'] || hasId;
  // console.log('seo', req.headers, req.url);
  if (req.url.includes('xml')) {
    try {
      const siteMap = (
        await HTTP.post(`/stores/${host}/sitemaps/download`, {
          path: req.url.replace('/', ''),
        })
      ).data;
      res.setHeader('Content-Type', 'application/xml');
      res.end(siteMap);
    } catch (err) {
      console.log(err);
      // next(err);
      res.writeHead(301, { Location: '/not-found' });
      res.end();
    }
  } else if (req.url.includes('txt')) {
    try {
      const robot = (await HTTP.post(`/stores/${host}/robots/download`, { path: req.url.replace('/', '') })).data;
      res.setHeader('Content-Type', 'text/plain');
      res.end(robot || 'empty');
    } catch (err) {
      console.log(err, err.response.data);
      // next(err);
      // res.writeHead(301, { Location: '/not-found' });
      res.setHeader('Content-Type', 'text/plain');
      res.end(['not found file ', err.toString(), req.url, host, JSON.stringify(req.headers)].join('; '));
    }
  } else {
    next();
  }
}
