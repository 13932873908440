import { encodeOpts as paramsSerializer } from '@/utils/encode-opts';

const codesApiFactory = (axios) => ({
  applyDiscountCode: async (storeId, code, data) =>
    (await axios.patch(`/stores/${storeId}/codes/${encodeURIComponent(code)}/apply`, data)).data,
  rejectDiscountCode: async (storeId, codeId, params) =>
    (await axios.delete(`/stores/${storeId}/codes/${codeId}`, { params, paramsSerializer })).data,
  getDiscountByOrder: async (storeId, orderId) =>
    (await axios.get(`/stores/${storeId}/codes/by-order/${orderId}`)).data,
  getDiscountByCode: async (storeId, params) =>
    (await axios.get(`/stores/${storeId}/codes`, { params, paramsSerializer })).data,
});

export { codesApiFactory };
