const layer = 'dataLayer';
class GTM {
  constructor(ctx) {
    this.ctx = ctx;
  }

  init() {
    window[layer] = window[layer] || [];

    this.pushEvent({
      event: 'gtm.js',
      'gtm.start': new Date().getTime(),
    });

    if (!this.hasDNT()) {
      this.initPageTracking();
    }
  }

  initPageTracking() {
    this.ctx.app.router.afterEach((to, from) => {
      setTimeout(() => {
        window[layer].push(
          to.gtm || { event: 'nuxtRoute', pageType: 'PageView', pageUrl: to.fullPath, routeName: to.name }
        );
      }, 0);
    });
  }

  pushEvent(obj) {
    try {
      if (!window || !window[layer]) {
        throw new Error('missing GTM dataLayer');
      }
      if (typeof obj !== 'object') {
        throw new Error('event should be an objetct');
      }
      if (!obj.hasOwnProperty('event')) {
        throw new Error('missing event property');
      }
      window[layer].push(obj);
    } catch (err) {
      console.error('[ERROR] [GTM]', err);
    }
  }

  hasDNT() {
    return (
      window.doNotTrack === '1' ||
      navigator.doNotTrack === 'yes' ||
      navigator.doNotTrack === '1' ||
      navigator.msDoNotTrack === '1' ||
      (window.external && window.external.msTrackingProtectionEnabled && window.external.msTrackingProtectionEnabled())
    );
  }
}

export default function(ctx, inject) {
  const $gtm = new GTM(ctx);
  inject('gtm', $gtm);
  $gtm.init();
}
