import apiRequests from '@/api/index';

const VERSION = require('../../package.json').version;

export default function ({ $axios, redirect, error, store, ...payload }, inject) {
  const apiToInject = {};
  Object.keys(apiRequests).forEach((apiFactory) => {
    const api = apiFactory.replace('ApiFactory', '');
    apiToInject[`${api}`] = apiRequests[apiFactory]($axios);
  });
  inject('api', apiToInject);
  $axios.onRequest((config) => {
    if (config.headers.common) {
      config.headers.common['version'] = VERSION;
      if (store.getters['storeInformation']) {
        config.headers.common['storeId'] = store.getters['storeInformation']._id;
        config.headers.common['storeUrl'] = store.getters['storeInformation'].storeUrl;
      }

      if ((payload?.req?.headers?.referer || '').match(/noCache/i) || store.getters['caching'] > Date.now()) {
        config.headers.common['nocachepage'] = 1;
        store.dispatch('setCaching');
      }
      if (!config.headers.common['Authorization'] && store.getters['auth/token'])
        config.headers.common['Authorization'] = store.getters['auth/token'];
      if (!config.headers.common['mpower-source'] && store.getters['auth/source']) {
        config.headers.common['mpower-source'] = store.getters['auth/source'];
      }
      if (!config.headers.common['mpower-source-id'] && store.getters['auth/sourceId']) {
        config.headers.common['mpower-source-id'] = store.getters['auth/sourceId'];
      }

      if (!config.headers.common['Authorization']) config.headers.common['apitoken'] = process.env.API_TOKEN || 'qweQj4giRJSdMNzB8g1XIa6t3YtRIHPH';
    }
  });
  $axios.onError((error) => {
    throw error;
  });
}
