import axios from 'axios';
const Cookie = process.client ? require('js-cookie') : undefined;
const getIpInfo = async () => {
  const referrer = Cookie.getJSON('referrer');
  const utmSource = Cookie.get('utm_source');
  const utmMedium = Cookie.get('utm_medium');
  const utmCampaign = Cookie.get('utm_campaign');

  const {
    ip,
    city,
    region,
    country_name: countryName,
    country_code: countryCode,
    continent_name: continentName,
    continent_code: continentCode,
    latitude,
    longitude,
    postal,
  } = (await axios.get(`https://api.ipdata.co?api-key=${process.env.GEOLOCATION_API_KEY}`)).data;

  return {
    referrer,
    utmSource,
    utmMedium,
    utmCampaign,
    location: {
      ip,
      city,
      region,
      continentCode,
      continentName,
      countryCode,
      countryName,
      latitude,
      longitude,
      postal,
    },
  };
};

export { getIpInfo };
