import mPowerMoment from '@/utils/mPowerMoment';

export const state = () => ({
  isDatePassed: false,
  loading: true,
});

export const getters = {
  isDatePassed: (state) => state.isDatePassed,
  isLoading: (state) => state.loading,
};

export const actions = {
  async getIsDatePassed({ commit, rootState }) {
    commit('SET_LOADING', true);
    const store = rootState.store;
    if (!store?.dontAllowOrdersAfterDate || !store?.distributor?.timeZone) {
      commit('SET_IS_DATE_PASSED', false);
      commit('SET_LOADING', false);
      return;
    }
    const currentDate = mPowerMoment(
      new Date()
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ')
    )?.tz(store.distributor.timeZone);
    const expiryDateTimezoneDate = mPowerMoment(store.dontAllowOrdersAfterDate)?.tz(store.distributor.timeZone);
    const expiryDate = mPowerMoment(expiryDateTimezoneDate)?.endOf('day');

    const isDatePassed = new Date(currentDate) > new Date(expiryDate);
    commit('SET_IS_DATE_PASSED', isDatePassed);
    commit('SET_LOADING', false);
  },
};

export const mutations = {
  SET_IS_DATE_PASSED(state, value) {
    state.isDatePassed = value;
  },
  SET_LOADING(state, value) {
    state.loading = value;
  },
};
