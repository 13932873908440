import infoFactories from '@/info';

export default function ({ $axios }, inject) {
  const infoToInject = {};
  Object.keys(infoFactories)
    .forEach((infoFactory) => {
      const info = infoFactory.replace('InfoFactory', '');
      infoToInject[`${info}`] = infoFactories[infoFactory]($axios);
    });
  inject('info', infoToInject);
}
