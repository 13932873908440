import { encodeOpts as paramsSerializer } from '@/utils/encode-opts';

const Cookie = process.client ? require('js-cookie') : undefined;
import { mapGetters, mapActions } from 'vuex';
import { findPriceByQuantity } from './productPrices';
export const calcOrderTotal = (po, currencyRate = 1) => {
  let price = 0;
  let charge = parseFloat(po.chargeTotal);
  let discount = parseFloat(po.discountTotal);
  if (po.decorationPOChargeTotal) charge += po.decorationPOChargeTotal;
  for (let i = 0; i < po.LineItemArray.length; i++) {
    if (po.LineItemArray[i].localProduct?.decorationProducts?.length) {
      discount = 0; //already included in unitPrice partDiscount
      for (let j = 0; j < po.LineItemArray[i].PartArray.length; j++) {
        const part = po.LineItemArray[i].PartArray[j];
        const unitPrice = partUnitPrice(po, part, i, false, j, currencyRate);
        price += unitPrice * part.Quantity.value;
      }
    } else {
      for (let j = 0; j < po.LineItemArray[i].PartArray.length; j++) {
        const part = po.LineItemArray[i].PartArray[j];
        price += part.unitPrice * part.Quantity.value;
      }
    }
  }
  return (price + charge - discount).roundCeil(6);
};
export const INVENTORY_EVENTS = {
  INVENTORY_START: 'INVENTORY_START',
  INVENTORY_FINISH: 'INVENTORY_FINISH',
};
export const calcPOPartTotal = (po, part) => {
  const unitPrice = partUnitPrice(po, part);
  return unitPrice * part.Quantity.value;
};
export const partUnitPrice = (po, part, i = 0, withoutDiscount = false, j = 0, currencyRate = 1) => {
  try {
    if (part.configurationType === 'Decorated') {
      const localProduct = po?.LineItemArray[i]?.localProduct;
      const decorList = localProduct.decorationProducts.map((el) => el._id || el);
      if (localProduct && localProduct.decorationProducts.length) {
        let partPrice = (po.childPos || [])
          .map((el) =>
            el.LineItemArray.find(
              (el) => el.PartArray[0] && el.localProduct && decorList.includes(el.localProduct._id || el.localProduct)
            )
          )
          .find((el) => el);
        if (partPrice) partPrice = partPrice.PartArray[j] || partPrice.PartArray[0];

        if (partPrice) {
          let partDecorationMargin = po.LineItemArray[i].localProduct.decorationProducts
            .map((el) => {
              let parts = el.parts.filter((e) => e._id === el.selectedPart);
              if (!parts.length) parts = el.parts;
              return parts.map((el) => {
                let item =
                  partPrice &&
                  (el.pricing.find(
                    (e) =>
                      e.unitCost === partPrice.unitCost &&
                      e.minQuantity >= partPrice.Quantity.value &&
                      e.minQuantity <= partPrice.Quantity.value
                  ) ||
                    el.pricing.find((e) => e.unitCost === partPrice.unitCost));
                if (!item) item = el.pricing[0];
                if (item && el.selected) {
                  item.selected = 1;
                }
                return item;
              });
            })
            .reduce((a, b) => [...a, ...b], [])
            .filter((e) => e);
          let selected = partDecorationMargin.find((e) => e.selected);

          if (!selected) selected = partDecorationMargin[0];
          partDecorationMargin = selected;
          const decorPrice = part.unitPrice;
          const discount = part.partDiscount + partPrice.partDiscount;
          const price = decorPrice + partPrice.unitPrice;

          if (withoutDiscount) {
            return {
              price,
              discount,
            };
          }

          return price - discount; //SUM of Blank and Decorated pricing
        } else {
          const productPart = po.LineItemArray[i].localProduct.parts.find((el) => el.partId === part.partId);
          partPrice = findPriceByQuantity(productPart, part.Quantity.value);
          if (partPrice) {
            return partPrice.unitPrice * currencyRate;
          }
        }
      }
    }
    if (withoutDiscount) {
      return {
        price: part.unitPrice,
        discount: part.partDiscount,
      };
    }
    return part.unitPrice;
  } catch (e) {
    // console.log(e);
    return 0;
  }
};
const wrapVal = (val) =>
  val
    ?.match(/[0-9A-Za-z]/gi)
    .join('')
    .toLowerCase();
let initedCart = 0;

export default {
  data() {
    return {
      loaded: false,
      productsInventory: [],
    };
  },
  computed: {
    ...mapGetters({
      loggedInCustomer: 'auth/loggedInUser',
      isCartSuccess: 'cart/isCartSuccess',
      orders: 'cart/orders',
    }),
    notAbleToBuyMessage() {
      const inventories = [...this.productsInventory].filter((i) => i);

      // console.log(
      //   'cart items notAbleToBuyMessage',
      //   this.orders.length,
      //   inventories.length,
      //   this.storeInformation.hideOutOfStockProducts || this.storeInformation.forceUserToCheckInventory,
      //   this.orders
      // );
      return this.orders
        .map((order) => {
          let message;
          const LineItem = order.LineItemArray?.find(
            (e) => e.localProduct && e.localProduct._id === order.productMongoId
          );
          const product = LineItem?.localProduct;

          if (product?.isAlwaysInStock) {
            return;
          }
          if (
            inventories.length &&
            (this.storeInformation.hideOutOfStockProducts || this.storeInformation.forceUserToCheckInventory) &&
            !product?.isAlwaysInStock
          ) {
            const ids = [product?._id, product?.primaryProductId, order.productMongoId].filter((i) => i);
            const inventory = inventories.find((i) => ids.includes(i.productId));
            if (!inventory) {
              return order;
            }
            if (!product?.isAlwaysInStock) {
              let part;
              const inventoryPart =
                inventory?.partInventories.find((e) => e.partId && wrapVal(e.partId) === wrapVal(order.partId)) ||
                inventory?.partInventories.find((einv) => {
                  part = LineItem.PartArray.find((p) => p.partId && wrapVal(p.partId) === wrapVal(einv.partId));
                  return part;
                }) ||
                inventory?.partInventories.find((einv) => {
                  part = LineItem.PartArray.find(
                    (p) => einv.partColor && wrapVal(einv.partColor) === wrapVal(p.partColor)
                  );

                  return part;
                });
              const quantity = (inventoryPart?.quantity && Number(inventoryPart.quantity.value)) || 0;
              const inventoryQuantity = quantity;
              const orderQuantity = typeof part.Quantity?.value !== undefined ? part.Quantity?.value : order.quantity;
              const disabled = !(quantity > 0);

              if (disabled) {
                message = `This product is currently out of stock`;
              } else if (orderQuantity > inventoryQuantity) {
                message = `Quantity entered exceeds available inventory of ${inventoryQuantity}`;
              }
            }
          }
          return {
            ...order,
            message,
          };
        })
        .filter((i) => i?.message);
    },
  },

  created() {
    this.onInit();
  },
  methods: {
    ...mapActions('cart', ['loadOrders', 'deleteOrder']),
    async onInit() {
      const self = this;
      if (process.client && !initedCart) {
        initedCart = 1;
        try {
          let cartOrders = Cookie.get('cartOrders') || undefined;
          cartOrders = cartOrders ? JSON.parse(cartOrders) : [];

          const customerId = self.loggedInCustomer && self.loggedInCustomer._id;
          const { path } = self.$route;
          const onlyCount = !(path.includes('checkout') || path.includes('cart'));
          await this.loadOrders({
            orders: cartOrders.map((i) => i._id),
            customerId,
            onlyCount,
            storeInformation: self.storeInformation,
            forceLoad: path.includes('cartitemsadded'),
          });
          if (self.storeInformation.forceUserToCheckInventory || self.storeInformation.hideOutOfStockProducts) {
            const inventoryProducts = this.orders.filter((i) => i.productMongoId).map((i) => i.productMongoId);
            if (inventoryProducts.length) {
              if (process.browser) document.body.dispatchEvent(new Event(INVENTORY_EVENTS.INVENTORY_START));
              while (self.productsInventory.length) {
                self.productsInventory.pop();
              }
              // console.log('check inventory', inventoryProducts);
              const inventories = (
                await self.$axios.get('products/inventory/check', {
                  params: {
                    products: inventoryProducts,
                    check: true,
                  },
                  paramsSerializer,
                })
              ).data;
              self.productsInventory.push(...inventories);
            }
          }
        } catch (e) {
          console.log('onInit:', e);
        } finally {
          initedCart = 0;
        }
      }
      self.loaded = true;
      if (process.browser)
        document.body.dispatchEvent(
          new CustomEvent(INVENTORY_EVENTS.INVENTORY_FINISH, { detail: { productsInventory: self.productsInventory } })
        );
    },
    getOrderStatusByCartType(cartType) {
      return cartType !== 'Cart' ? `${cartType} Cart` : cartType;
    },
    productDiscounts(cartType) {
      const status = cartType !== 'Cart' ? `${cartType} Cart` : cartType;
      return [
        ...this.orders.filter((i) => i.orderId && i.orderStatus === status),
        ...this.orders
          .filter((el) => el.childPos && el.orderStatus === status)
          .map((el) => el.childPos)
          .reduce((a, b) => [...a, ...b], []),
      ]
        .map((el) => parseFloat(el.productDiscount))
        .reduce((cur, next) => parseFloat(cur) + parseFloat(next), 0);
    },
    total(cartType, orderDiscount = 0) {
      const status = this.getOrderStatusByCartType(cartType);
      const total = this.orders
        .filter((i) => i.orderId && i.orderStatus === status)
        .map((o) => calcOrderTotal(o))
        .reduce((a, b) => a + b, 0);

      return Math.max(total - orderDiscount, 0);
    },
    ordersByCartType(cartType) {
      const status = this.getOrderStatusByCartType(cartType);
      const data = _.groupBy(this.orders, 'orderStatus');
      return data[status] || [];
    },
    ordersCountByType(cartType) {
      const orders = this.ordersByCartType(cartType);
      return orders.length;
    },
    hasOrdersNotDependFromType() {
      return this.orders.length;
    },
    hasOrders(cartType) {
      return this.ordersCountByType(cartType) > 0;
    },
    async deleteCartItem(order, cartType) {
      await this.deleteOrder({ order, cartType });
      let cartOrders = Cookie.get('cartOrders') || undefined;
      cartOrders = cartOrders ? JSON.parse(cartOrders) : [];
      cartOrders = cartOrders.filter((i) => i._id !== order._id);
    },
  },
};
