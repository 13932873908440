function toUrlSlug(str) {
  return (
    (str &&
      str
        .toString()
        .trim()
        .replace(/\W+$/, '')
        .replace(/\W+/g, '-')
        .toLowerCase()) ||
    ''
  );
}

function getQueryFromUrl(path) {
  const res = path.match(/search\/([^\/?]+)/);
  return res && res[1];
}
function changeQuery(query = {}, updatePartQuery = false) {
  if (process.client && updatePartQuery) {
    const { origin, pathname } = window.location;
    // const query = JSON.parse(JSON.stringify(changeQuery));
    // Object.assign(query, { partId: color.partId });
    history.replaceState({}, null, `${origin}${pathname}?${new URLSearchParams(query).toString()}`);
  }
}

export { toUrlSlug, getQueryFromUrl, changeQuery };
