export default ({ app }) => {
  let checkingVersion = false;
  app.router.afterEach(async (to, from) => {
    if (checkingVersion || ['development', 'local'].includes(process.env.NODE_ENV)) {
      return;
    }
    try {
      checkingVersion = true;
      const [route] = from?.matched || [];
      if (route) {
        const currentVersion = process.env.STORE_VERSION || process.env.CODEBUILD_RESOLVED_SOURCE_VERSION;
        const response = await fetch('/app/version');
        const { version } = await response.json();
        if (version && currentVersion && currentVersion !== version) {
          // location.reload()
          console.log('location.reload...');
        }
      }
    } catch (e) {}
    checkingVersion = false
  });
}