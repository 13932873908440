import { CLEAR } from '@/store/constants';

export const state = () => ({
  creditsAllowances: {
    allowance: [],
    credit: [],
  },
});

export const getters = {
  creditsAllowances: (state) => state.creditsAllowances,
};

export const actions = {
  clear({ commit }) {
    commit(CLEAR);
  },
  async getCreditsAllowances({ commit, rootState }, data) {
    const { order, discount } = data || {};
    let creditsAllowances = {
      allowance: [],
      credit: [],
    };
    const loggedUser = rootState.auth.user;
    if (loggedUser || order) {
      try {
        const params = {
          storeId: rootState.store._id,
          onlyTotal: true,
        };
        if (loggedUser?._id) {
          params.customerId = loggedUser?._id;
        } else if (order?._id) {
          params.order = order?._id;
        }
        creditsAllowances = (await this.$api.creditAllowances.getCreditsAllowances(params))[0];
      } catch (e) {
        console.log(e);
      }
    }
    if (discount?.type === 'Allowance') {
      if (!creditsAllowances.allowance.length) {
        creditsAllowances.allowance.push({ sum: 0 });
      }
      creditsAllowances.allowance[0].sum += discount.discount;
    }
    if (discount?.type === 'Credit') {
      if (!creditsAllowances.credit.length) {
        creditsAllowances.credit.push({ sum: 0 });
      }
      creditsAllowances.credit[0].sum += discount.discount;
    }
    commit('SET_CREDITS_ALLOWANCES', creditsAllowances);
  },
};

export const mutations = {
  SET_CREDITS_ALLOWANCES(state, creditsAllowances) {
    state.creditsAllowances = creditsAllowances;
  },
  [CLEAR](state) {
    Object.assign(state, {
      creditsAllowances: {
        allowance: [],
        credit: [],
      },
    });
  },
};
