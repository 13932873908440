export default (ctx, inject) => {
  // make lazy component loaders available for hydratableLoader
  // lets set all component options of components which were detected by nuxt/components including its loaders as prop of each component

  // now we are going to inject all the dynamic webpack imports to each component as single property with its own chunk
  const loaders = {
    LazyReportingViewFormSettingsFormModal: () => import('../client/components/account/admin/Reporting/View/Form/Settings/ReportingViewFormSettingsFormModal.vue' /* webpackChunkName: "components/reporting-view-form-settings-form-modal" */).then(c => c.default || c),
      LazyReportingFormSettings: () => import('../client/components/account/admin/Reporting/View/Form/Settings/index.vue' /* webpackChunkName: "components/reporting-form-settings" */).then(c => c.default || c),
      LazyReportingFormSettingsUtils: () => import('../client/components/account/admin/Reporting/View/Form/Settings/utils.js' /* webpackChunkName: "components/reporting-form-settings-utils" */).then(c => c.default || c),
      LazyReportingFormReportFormInput: () => import('../client/components/account/admin/Reporting/View/Form/ReportFormInput.vue' /* webpackChunkName: "components/reporting-form-report-form-input" */).then(c => c.default || c),
      LazyReportingFormReportFormModal: () => import('../client/components/account/admin/Reporting/View/Form/ReportFormModal.vue' /* webpackChunkName: "components/reporting-form-report-form-modal" */).then(c => c.default || c),
      LazyReportingFormFiltersReportFilterFormModal: () => import('../client/components/account/admin/Reporting/View/Form/Filters/ReportFilterFormModal.vue' /* webpackChunkName: "components/reporting-form-filters-report-filter-form-modal" */).then(c => c.default || c),
      LazyReportingFormFilters: () => import('../client/components/account/admin/Reporting/View/Form/Filters/index.vue' /* webpackChunkName: "components/reporting-form-filters" */).then(c => c.default || c),
      LazyConfigureproductConfigureProduct1: () => import('../client/components/configureproduct/ConfigureProduct1.vue' /* webpackChunkName: "components/configureproduct-configure-product1" */).then(c => c.default || c),
      LazyConfigureproductDesignModal: () => import('../client/components/configureproduct/DesignModal.vue' /* webpackChunkName: "components/configureproduct-design-modal" */).then(c => c.default || c),
      LazyConfigureproductFormConfigure: () => import('../client/components/configureproduct/FormConfigure.vue' /* webpackChunkName: "components/configureproduct-form-configure" */).then(c => c.default || c),
      LazyConfigureproductFormConfigureColor: () => import('../client/components/configureproduct/FormConfigureColor.vue' /* webpackChunkName: "components/configureproduct-form-configure-color" */).then(c => c.default || c),
      LazyConfigureproductFormConfigureSizes: () => import('../client/components/configureproduct/FormConfigureSizes.vue' /* webpackChunkName: "components/configureproduct-form-configure-sizes" */).then(c => c.default || c),
      LazyConfigureproductPreloader: () => import('../client/components/configureproduct/Preloader.vue' /* webpackChunkName: "components/configureproduct-preloader" */).then(c => c.default || c),
      LazyConfigureproductProductColor: () => import('../client/components/configureproduct/ProductColor.vue' /* webpackChunkName: "components/configureproduct-product-color" */).then(c => c.default || c),
      LazyConfigureproductProductDecoration: () => import('../client/components/configureproduct/ProductDecoration.vue' /* webpackChunkName: "components/configureproduct-product-decoration" */).then(c => c.default || c),
      LazyConfigureproductProductDefaultDecoration: () => import('../client/components/configureproduct/ProductDefaultDecoration.vue' /* webpackChunkName: "components/configureproduct-product-default-decoration" */).then(c => c.default || c),
      LazyConfigureproductProductDefaultDecorationDropdown: () => import('../client/components/configureproduct/ProductDefaultDecorationDropdown.vue' /* webpackChunkName: "components/configureproduct-product-default-decoration-dropdown" */).then(c => c.default || c),
      LazyConfigureproductProductDefaultDecorationDropdownOption: () => import('../client/components/configureproduct/ProductDefaultDecorationDropdownOption.vue' /* webpackChunkName: "components/configureproduct-product-default-decoration-dropdown-option" */).then(c => c.default || c),
      LazyConfigureproductProductDefaultDecorationLocationDropdown: () => import('../client/components/configureproduct/ProductDefaultDecorationLocationDropdown.vue' /* webpackChunkName: "components/configureproduct-product-default-decoration-location-dropdown" */).then(c => c.default || c),
      LazyConfigureproductProductDefaultDecorationV1: () => import('../client/components/configureproduct/ProductDefaultDecorationV1.vue' /* webpackChunkName: "components/configureproduct-product-default-decoration-v1" */).then(c => c.default || c),
      LazyConfigureproductProductDesign: () => import('../client/components/configureproduct/ProductDesign.vue' /* webpackChunkName: "components/configureproduct-product-design" */).then(c => c.default || c),
      LazyConfigureproductProductQuantityTable: () => import('../client/components/configureproduct/ProductQuantityTable.vue' /* webpackChunkName: "components/configureproduct-product-quantity-table" */).then(c => c.default || c),
      LazyConfigureproductProductTotal: () => import('../client/components/configureproduct/ProductTotal.vue' /* webpackChunkName: "components/configureproduct-product-total" */).then(c => c.default || c),
      LazyConfigureproductTypesetSection: () => import('../client/components/configureproduct/TypesetSection.vue' /* webpackChunkName: "components/configureproduct-typeset-section" */).then(c => c.default || c),
      LazyAddressFields: () => import('../client/components/AddressFields.vue' /* webpackChunkName: "components/address-fields" */).then(c => c.default || c),
      LazyAddressInformation: () => import('../client/components/AddressInformation.vue' /* webpackChunkName: "components/address-information" */).then(c => c.default || c),
      LazyBDropdownHover: () => import('../client/components/BDropdownHover.vue' /* webpackChunkName: "components/b-dropdown-hover" */).then(c => c.default || c),
      LazyButtonInventoryNext: () => import('../client/components/ButtonInventoryNext.vue' /* webpackChunkName: "components/button-inventory-next" */).then(c => c.default || c),
      LazyCartItemInfo: () => import('../client/components/CartItemInfo.vue' /* webpackChunkName: "components/cart-item-info" */).then(c => c.default || c),
      LazyCartItems: () => import('../client/components/CartItems.vue' /* webpackChunkName: "components/cart-items" */).then(c => c.default || c),
      LazyCartItemsViewPartPrice: () => import('../client/components/CartItemsViewPartPrice.vue' /* webpackChunkName: "components/cart-items-view-part-price" */).then(c => c.default || c),
      LazyCategoryProductsList: () => import('../client/components/CategoryProductsList.vue' /* webpackChunkName: "components/category-products-list" */).then(c => c.default || c),
      LazyCountUpCounter: () => import('../client/components/CountUpCounter.vue' /* webpackChunkName: "components/count-up-counter" */).then(c => c.default || c),
      LazyCountdown: () => import('../client/components/Countdown.vue' /* webpackChunkName: "components/countdown" */).then(c => c.default || c),
      LazyCustomerCapture: () => import('../client/components/CustomerCapture.vue' /* webpackChunkName: "components/customer-capture" */).then(c => c.default || c),
      LazyCustomerInformation: () => import('../client/components/CustomerInformation.vue' /* webpackChunkName: "components/customer-information" */).then(c => c.default || c),
      LazyCustomerInvoice: () => import('../client/components/CustomerInvoice.vue' /* webpackChunkName: "components/customer-invoice" */).then(c => c.default || c),
      LazyCustomerInvoicePrint: () => import('../client/components/CustomerInvoicePrint.vue' /* webpackChunkName: "components/customer-invoice-print" */).then(c => c.default || c),
      LazyDeleteButton: () => import('../client/components/DeleteButton.vue' /* webpackChunkName: "components/delete-button" */).then(c => c.default || c),
      LazyDesigns: () => import('../client/components/Designs.vue' /* webpackChunkName: "components/designs" */).then(c => c.default || c),
      LazyFacetsList: () => import('../client/components/FacetsList.vue' /* webpackChunkName: "components/facets-list" */).then(c => c.default || c),
      LazyFacetsProductGrid: () => import('../client/components/FacetsProductGrid.vue' /* webpackChunkName: "components/facets-product-grid" */).then(c => c.default || c),
      LazyFavoritesModal: () => import('../client/components/FavoritesModal.vue' /* webpackChunkName: "components/favorites-modal" */).then(c => c.default || c),
      LazyFilesPreview: () => import('../client/components/FilesPreview.vue' /* webpackChunkName: "components/files-preview" */).then(c => c.default || c),
      LazyFundraisingProgress: () => import('../client/components/FundraisingProgress.vue' /* webpackChunkName: "components/fundraising-progress" */).then(c => c.default || c),
      LazyGoogleAutocomplete: () => import('../client/components/GoogleAutocomplete.vue' /* webpackChunkName: "components/google-autocomplete" */).then(c => c.default || c),
      LazyIEWarning: () => import('../client/components/IEWarning.vue' /* webpackChunkName: "components/i-e-warning" */).then(c => c.default || c),
      LazyListPagesByCategory: () => import('../client/components/ListPagesByCategory.vue' /* webpackChunkName: "components/list-pages-by-category" */).then(c => c.default || c),
      LazyLoader: () => import('../client/components/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => c.default || c),
      LazyLoadingPage: () => import('../client/components/LoadingPage.vue' /* webpackChunkName: "components/loading-page" */).then(c => c.default || c),
      LazyMPowerAutocomplete: () => import('../client/components/MPowerAutocomplete.vue' /* webpackChunkName: "components/m-power-autocomplete" */).then(c => c.default || c),
      LazyMPowerDesigner: () => import('../client/components/MPowerDesigner.vue' /* webpackChunkName: "components/m-power-designer" */).then(c => c.default || c),
      LazyMPowerImg: () => import('../client/components/MPowerImg.vue' /* webpackChunkName: "components/m-power-img" */).then(c => c.default || c),
      LazyMPowerLightBox: () => import('../client/components/MPowerLightBox.vue' /* webpackChunkName: "components/m-power-light-box" */).then(c => c.default || c),
      LazyMPowerMultiSelect: () => import('../client/components/MPowerMultiSelect.vue' /* webpackChunkName: "components/m-power-multi-select" */).then(c => c.default || c),
      LazyMPowerQuantityTable: () => import('../client/components/MPowerQuantityTable.vue' /* webpackChunkName: "components/m-power-quantity-table" */).then(c => c.default || c),
      LazyMPowerTable: () => import('../client/components/MPowerTable.vue' /* webpackChunkName: "components/m-power-table" */).then(c => c.default || c),
      LazyMPowerTableClient: () => import('../client/components/MPowerTableClient.vue' /* webpackChunkName: "components/m-power-table-client" */).then(c => c.default || c),
      LazyMPowerTableClientWrapper: () => import('../client/components/MPowerTableClientWrapper.vue' /* webpackChunkName: "components/m-power-table-client-wrapper" */).then(c => c.default || c),
      LazyMPowerTableWrapper: () => import('../client/components/MPowerTableWrapper.vue' /* webpackChunkName: "components/m-power-table-wrapper" */).then(c => c.default || c),
      LazyMPowerValidateInput: () => import('../client/components/MPowerValidateInput.vue' /* webpackChunkName: "components/m-power-validate-input" */).then(c => c.default || c),
      LazyMPowerValidateInputWrapper: () => import('../client/components/MPowerValidateInputWrapper.vue' /* webpackChunkName: "components/m-power-validate-input-wrapper" */).then(c => c.default || c),
      LazyMPowerValidatedInput: () => import('../client/components/MPowerValidatedInput.vue' /* webpackChunkName: "components/m-power-validated-input" */).then(c => c.default || c),
      LazyNuxtDynamic: () => import('../client/components/NuxtDynamic.vue' /* webpackChunkName: "components/nuxt-dynamic" */).then(c => c.default || c),
      LazyNuxtImg: () => import('../client/components/NuxtImg.vue' /* webpackChunkName: "components/nuxt-img" */).then(c => c.default || c),
      LazyOrderInfo: () => import('../client/components/OrderInfo.vue' /* webpackChunkName: "components/order-info" */).then(c => c.default || c),
      LazyOrderInfoShipment: () => import('../client/components/OrderInfoShipment.vue' /* webpackChunkName: "components/order-info-shipment" */).then(c => c.default || c),
      LazyPasswordInput: () => import('../client/components/PasswordInput.vue' /* webpackChunkName: "components/password-input" */).then(c => c.default || c),
      LazyProductRestrictionCodeInput: () => import('../client/components/ProductRestrictionCodeInput.vue' /* webpackChunkName: "components/product-restriction-code-input" */).then(c => c.default || c),
      LazyProductSpecialBanner: () => import('../client/components/ProductSpecialBanner.vue' /* webpackChunkName: "components/product-special-banner" */).then(c => c.default || c),
      LazyProductsGrid: () => import('../client/components/ProductsGrid.vue' /* webpackChunkName: "components/products-grid" */).then(c => c.default || c),
      LazyProductsGridTemplate: () => import('../client/components/ProductsGridTemplate.vue' /* webpackChunkName: "components/products-grid-template" */).then(c => c.default || c),
      LazyRedemptionCodeModal: () => import('../client/components/RedemptionCodeModal.vue' /* webpackChunkName: "components/redemption-code-modal" */).then(c => c.default || c),
      LazyReorderBtn: () => import('../client/components/ReorderBtn.vue' /* webpackChunkName: "components/reorder-btn" */).then(c => c.default || c),
      LazySelectedDesignCard: () => import('../client/components/SelectedDesignCard.vue' /* webpackChunkName: "components/selected-design-card" */).then(c => c.default || c),
      LazyShippingFixModal: () => import('../client/components/ShippingFixModal.vue' /* webpackChunkName: "components/shipping-fix-modal" */).then(c => c.default || c),
      LazySocialShare: () => import('../client/components/SocialShare.vue' /* webpackChunkName: "components/social-share" */).then(c => c.default || c),
      LazyStoreDesigns: () => import('../client/components/StoreDesigns.vue' /* webpackChunkName: "components/store-designs" */).then(c => c.default || c),
      LazyStorePageCard: () => import('../client/components/StorePageCard.vue' /* webpackChunkName: "components/store-page-card" */).then(c => c.default || c),
      LazySuppliersList: () => import('../client/components/SuppliersList.vue' /* webpackChunkName: "components/suppliers-list" */).then(c => c.default || c),
      LazyTemplateByCategory: () => import('../client/components/TemplateByCategory.vue' /* webpackChunkName: "components/template-by-category" */).then(c => c.default || c),
      LazyTemplates: () => import('../client/components/Templates.vue' /* webpackChunkName: "components/templates" */).then(c => c.default || c),
      LazyUserAvatar: () => import('../client/components/UserAvatar.vue' /* webpackChunkName: "components/user-avatar" */).then(c => c.default || c),
      LazyVueCsvImport: () => import('../client/components/VueCsvImport.vue' /* webpackChunkName: "components/vue-csv-import" */).then(c => c.default || c),
      LazyNoscript: () => import('../client/components/noscript.vue' /* webpackChunkName: "components/noscript" */).then(c => c.default || c),
      LazyPageGenerator: () => import('../client/components/pageGenerator.vue' /* webpackChunkName: "components/page-generator" */).then(c => c.default || c),
      LazyItem: () => import('../client/components/Comments/Item.vue' /* webpackChunkName: "components/item" */).then(c => c.default || c),
      LazyComments: () => import('../client/components/Comments/index.vue' /* webpackChunkName: "components/comments" */).then(c => c.default || c),
      LazyAddresses: () => import('../client/components/account/Addresses.vue' /* webpackChunkName: "components/addresses" */).then(c => c.default || c),
      LazyCreateAccount: () => import('../client/components/account/CreateAccount.vue' /* webpackChunkName: "components/create-account" */).then(c => c.default || c),
      LazyMyAccount1: () => import('../client/components/account/MyAccount1.vue' /* webpackChunkName: "components/my-account1" */).then(c => c.default || c),
      LazyOrders: () => import('../client/components/account/Orders.vue' /* webpackChunkName: "components/orders" */).then(c => c.default || c),
      LazyPresentations: () => import('../client/components/account/Presentations.vue' /* webpackChunkName: "components/presentations" */).then(c => c.default || c),
      LazyAccountManager1: () => import('../client/components/accountmanager/AccountManager1.vue' /* webpackChunkName: "components/account-manager1" */).then(c => c.default || c),
      LazyVirtualArtproof1: () => import('../client/components/artproof/VirtualArtproof1.vue' /* webpackChunkName: "components/virtual-artproof1" */).then(c => c.default || c),
      LazyButton1: () => import('../client/components/basic/Button1.vue' /* webpackChunkName: "components/button1" */).then(c => c.default || c),
      LazyConfirmation1: () => import('../client/components/basic/Confirmation1.vue' /* webpackChunkName: "components/confirmation1" */).then(c => c.default || c),
      LazyDesignBlock1: () => import('../client/components/basic/DesignBlock1.vue' /* webpackChunkName: "components/design-block1" */).then(c => c.default || c),
      LazyHeading1: () => import('../client/components/basic/Heading1.vue' /* webpackChunkName: "components/heading1" */).then(c => c.default || c),
      LazyHeading2: () => import('../client/components/basic/Heading2.vue' /* webpackChunkName: "components/heading2" */).then(c => c.default || c),
      LazyHeading3: () => import('../client/components/basic/Heading3.vue' /* webpackChunkName: "components/heading3" */).then(c => c.default || c),
      LazyHtml1: () => import('../client/components/basic/Html1.vue' /* webpackChunkName: "components/html1" */).then(c => c.default || c),
      LazyImage1: () => import('../client/components/basic/Image1.vue' /* webpackChunkName: "components/image1" */).then(c => c.default || c),
      LazyLink1: () => import('../client/components/basic/Link1.vue' /* webpackChunkName: "components/link1" */).then(c => c.default || c),
      LazyListOrdered1: () => import('../client/components/basic/ListOrdered1.vue' /* webpackChunkName: "components/list-ordered1" */).then(c => c.default || c),
      LazyListUnordered1: () => import('../client/components/basic/ListUnordered1.vue' /* webpackChunkName: "components/list-unordered1" */).then(c => c.default || c),
      LazyText1: () => import('../client/components/basic/Text1.vue' /* webpackChunkName: "components/text1" */).then(c => c.default || c),
      LazyBlogList1: () => import('../client/components/blog/BlogList1.vue' /* webpackChunkName: "components/blog-list1" */).then(c => c.default || c),
      LazyBlogPost1: () => import('../client/components/blog/BlogPost1.vue' /* webpackChunkName: "components/blog-post1" */).then(c => c.default || c),
      LazyBlogPostBreadcrumbs: () => import('../client/components/blog/BlogPostBreadcrumbs.vue' /* webpackChunkName: "components/blog-post-breadcrumbs" */).then(c => c.default || c),
      LazyBlogPostProducts1: () => import('../client/components/blog/BlogPostProducts1.vue' /* webpackChunkName: "components/blog-post-products1" */).then(c => c.default || c),
      LazyBlogPostsList1: () => import('../client/components/blog/BlogPostsList1.vue' /* webpackChunkName: "components/blog-posts-list1" */).then(c => c.default || c),
      LazySavedBoxItem: () => import('../client/components/boxItems/SavedBoxItem.vue' /* webpackChunkName: "components/saved-box-item" */).then(c => c.default || c),
      LazyUnsavedBoxItem: () => import('../client/components/boxItems/UnsavedBoxItem.vue' /* webpackChunkName: "components/unsaved-box-item" */).then(c => c.default || c),
      LazyBoxItems: () => import('../client/components/boxItems/index.vue' /* webpackChunkName: "components/box-items" */).then(c => c.default || c),
      LazyCarousel1: () => import('../client/components/carousel/Carousel1.vue' /* webpackChunkName: "components/carousel1" */).then(c => c.default || c),
      LazyCarousel2: () => import('../client/components/carousel/Carousel2.vue' /* webpackChunkName: "components/carousel2" */).then(c => c.default || c),
      LazyCarousel3: () => import('../client/components/carousel/Carousel3.vue' /* webpackChunkName: "components/carousel3" */).then(c => c.default || c),
      LazyCart1: () => import('../client/components/cart/Cart1.vue' /* webpackChunkName: "components/cart1" */).then(c => c.default || c),
      LazyCartItemsAdded: () => import('../client/components/cartitemsadded/CartItemsAdded.vue' /* webpackChunkName: "components/cart-items-added" */).then(c => c.default || c),
      LazyCartItemsAdded1: () => import('../client/components/cartitemsadded/CartItemsAdded1.vue' /* webpackChunkName: "components/cart-items-added1" */).then(c => c.default || c),
      LazyCreateAccountForm: () => import('../client/components/cartitemsadded/CreateAccountForm.vue' /* webpackChunkName: "components/create-account-form" */).then(c => c.default || c),
      LazySignInForm: () => import('../client/components/cartitemsadded/SignInForm.vue' /* webpackChunkName: "components/sign-in-form" */).then(c => c.default || c),
      LazyCategories1: () => import('../client/components/categories/Categories1.vue' /* webpackChunkName: "components/categories1" */).then(c => c.default || c),
      LazyCategoriesList1: () => import('../client/components/categories/CategoriesList1.vue' /* webpackChunkName: "components/categories-list1" */).then(c => c.default || c),
      LazyCategoryBreadcrumb1: () => import('../client/components/categories/CategoryBreadcrumb1.vue' /* webpackChunkName: "components/category-breadcrumb1" */).then(c => c.default || c),
      LazyChangePassword1: () => import('../client/components/changepassword/ChangePassword1.vue' /* webpackChunkName: "components/change-password1" */).then(c => c.default || c),
      LazyCheckout1: () => import('../client/components/checkout/Checkout1.vue' /* webpackChunkName: "components/checkout1" */).then(c => c.default || c),
      LazyCheckoutAddress: () => import('../client/components/checkout/CheckoutAddress.vue' /* webpackChunkName: "components/checkout-address" */).then(c => c.default || c),
      LazyCreditAllowance: () => import('../client/components/checkout/CreditAllowance.vue' /* webpackChunkName: "components/credit-allowance" */).then(c => c.default || c),
      LazyDiscountCode: () => import('../client/components/checkout/DiscountCode.vue' /* webpackChunkName: "components/discount-code" */).then(c => c.default || c),
      LazyDocumentPayment: () => import('../client/components/checkout/DocumentPayment.vue' /* webpackChunkName: "components/document-payment" */).then(c => c.default || c),
      LazyShipmentAddress: () => import('../client/components/checkout/ShipmentAddress.vue' /* webpackChunkName: "components/shipment-address" */).then(c => c.default || c),
      LazySubmitToJagger: () => import('../client/components/checkout/SubmitToJagger.vue' /* webpackChunkName: "components/submit-to-jagger" */).then(c => c.default || c),
      LazyColorConverter1: () => import('../client/components/colorTools/ColorConverter1.vue' /* webpackChunkName: "components/color-converter1" */).then(c => c.default || c),
      LazyImageColorPalette1: () => import('../client/components/colorTools/ImageColorPalette1.vue' /* webpackChunkName: "components/image-color-palette1" */).then(c => c.default || c),
      LazyContactForm1: () => import('../client/components/contactForm/ContactForm1.vue' /* webpackChunkName: "components/contact-form1" */).then(c => c.default || c),
      LazyDesignCustomerArtworks: () => import('../client/components/designer/DesignCustomerArtworks.vue' /* webpackChunkName: "components/design-customer-artworks" */).then(c => c.default || c),
      LazyDesignCustomerArtworksGrid: () => import('../client/components/designer/DesignCustomerArtworksGrid.vue' /* webpackChunkName: "components/design-customer-artworks-grid" */).then(c => c.default || c),
      LazyDesignImages: () => import('../client/components/designer/DesignImages.vue' /* webpackChunkName: "components/design-images" */).then(c => c.default || c),
      LazyDesignProduct1: () => import('../client/components/designer/DesignProduct1.vue' /* webpackChunkName: "components/design-product1" */).then(c => c.default || c),
      LazyError1: () => import('../client/components/error/Error1.vue' /* webpackChunkName: "components/error1" */).then(c => c.default || c),
      LazyFacets1: () => import('../client/components/facets/Facets1.vue' /* webpackChunkName: "components/facets1" */).then(c => c.default || c),
      LazyFavorites1: () => import('../client/components/favorites/Favorites1.vue' /* webpackChunkName: "components/favorites1" */).then(c => c.default || c),
      LazyCategoriesFilter: () => import('../client/components/filters/CategoriesFilter.vue' /* webpackChunkName: "components/categories-filter" */).then(c => c.default || c),
      LazyCategoriesFilterItem: () => import('../client/components/filters/CategoriesFilterItem.vue' /* webpackChunkName: "components/categories-filter-item" */).then(c => c.default || c),
      LazyCategoriesFilterNestedItem: () => import('../client/components/filters/CategoriesFilterNestedItem.vue' /* webpackChunkName: "components/categories-filter-nested-item" */).then(c => c.default || c),
      LazyCategoryChildsFilter: () => import('../client/components/filters/CategoryChildsFilter.vue' /* webpackChunkName: "components/category-childs-filter" */).then(c => c.default || c),
      LazyFacetsFilter: () => import('../client/components/filters/FacetsFilter.vue' /* webpackChunkName: "components/facets-filter" */).then(c => c.default || c),
      LazyInventoryFilter: () => import('../client/components/filters/InventoryFilter.vue' /* webpackChunkName: "components/inventory-filter" */).then(c => c.default || c),
      LazyPriceFilter: () => import('../client/components/filters/PriceFilter.vue' /* webpackChunkName: "components/price-filter" */).then(c => c.default || c),
      LazyProductList: () => import('../client/components/filters/ProductList.vue' /* webpackChunkName: "components/product-list" */).then(c => c.default || c),
      LazyProductListDetails: () => import('../client/components/filters/ProductListDetails.vue' /* webpackChunkName: "components/product-list-details" */).then(c => c.default || c),
      LazyProductsFilters: () => import('../client/components/filters/ProductsFilters.vue' /* webpackChunkName: "components/products-filters" */).then(c => c.default || c),
      LazySearchFilter: () => import('../client/components/filters/SearchFilter.vue' /* webpackChunkName: "components/search-filter" */).then(c => c.default || c),
      LazySelectedFilters: () => import('../client/components/filters/SelectedFilters.vue' /* webpackChunkName: "components/selected-filters" */).then(c => c.default || c),
      LazySortProductList: () => import('../client/components/filters/SortProductList.vue' /* webpackChunkName: "components/sort-product-list" */).then(c => c.default || c),
      LazySortProducts: () => import('../client/components/filters/SortProducts.vue' /* webpackChunkName: "components/sort-products" */).then(c => c.default || c),
      LazySupplierFilter: () => import('../client/components/filters/SupplierFilter.vue' /* webpackChunkName: "components/supplier-filter" */).then(c => c.default || c),
      LazyFlex1: () => import('../client/components/flex/Flex1.vue' /* webpackChunkName: "components/flex1" */).then(c => c.default || c),
      LazyFooter1: () => import('../client/components/footer/Footer1.vue' /* webpackChunkName: "components/footer1" */).then(c => c.default || c),
      LazyForgotPassword1: () => import('../client/components/forgotpassword/ForgotPassword1.vue' /* webpackChunkName: "components/forgot-password1" */).then(c => c.default || c),
      LazyNewsletterSignup1: () => import('../client/components/forms/NewsletterSignup1.vue' /* webpackChunkName: "components/newsletter-signup1" */).then(c => c.default || c),
      LazyGrid1: () => import('../client/components/grid/Grid1.vue' /* webpackChunkName: "components/grid1" */).then(c => c.default || c),
      LazyGrid2: () => import('../client/components/grid/Grid2.vue' /* webpackChunkName: "components/grid2" */).then(c => c.default || c),
      LazyInfiniteScroll1: () => import('../client/components/infinitescroll/InfiniteScroll1.vue' /* webpackChunkName: "components/infinite-scroll1" */).then(c => c.default || c),
      LazyInfoBar1: () => import('../client/components/infobar/InfoBar1.vue' /* webpackChunkName: "components/info-bar1" */).then(c => c.default || c),
      LazyInstagramFeed1: () => import('../client/components/instagram/InstagramFeed1.vue' /* webpackChunkName: "components/instagram-feed1" */).then(c => c.default || c),
      LazyJumbotron1: () => import('../client/components/jumbotron/Jumbotron1.vue' /* webpackChunkName: "components/jumbotron1" */).then(c => c.default || c),
      LazyJumbotron2: () => import('../client/components/jumbotron/Jumbotron2.vue' /* webpackChunkName: "components/jumbotron2" */).then(c => c.default || c),
      LazyMegamenu1: () => import('../client/components/megamenu/Megamenu1.vue' /* webpackChunkName: "components/megamenu1" */).then(c => c.default || c),
      LazyMegamenu2: () => import('../client/components/megamenu/Megamenu2.vue' /* webpackChunkName: "components/megamenu2" */).then(c => c.default || c),
      LazyFiltersModal: () => import('../client/components/modals/FiltersModal.vue' /* webpackChunkName: "components/filters-modal" */).then(c => c.default || c),
      LazyModal1: () => import('../client/components/modals/Modal1.vue' /* webpackChunkName: "components/modal1" */).then(c => c.default || c),
      LazyAvailableItems: () => import('../client/components/navbar/AvailableItems.vue' /* webpackChunkName: "components/available-items" */).then(c => c.default || c),
      LazyCategoriesMenuItems: () => import('../client/components/navbar/CategoriesMenuItems.vue' /* webpackChunkName: "components/categories-menu-items" */).then(c => c.default || c),
      LazyNavLink: () => import('../client/components/navbar/NavLink.vue' /* webpackChunkName: "components/nav-link" */).then(c => c.default || c),
      LazyNavbar1: () => import('../client/components/navbar/Navbar1.vue' /* webpackChunkName: "components/navbar1" */).then(c => c.default || c),
      LazyNavbar2: () => import('../client/components/navbar/Navbar2.vue' /* webpackChunkName: "components/navbar2" */).then(c => c.default || c),
      LazyOrderApproval1: () => import('../client/components/orderApproval/OrderApproval1.vue' /* webpackChunkName: "components/order-approval1" */).then(c => c.default || c),
      LazyOrderPrintView: () => import('../client/components/orderView/OrderPrintView.vue' /* webpackChunkName: "components/order-print-view" */).then(c => c.default || c),
      LazyOrderView1: () => import('../client/components/orderView/OrderView1.vue' /* webpackChunkName: "components/order-view1" */).then(c => c.default || c),
      LazyLastOrdersProductsList1: () => import('../client/components/ordersProducts/LastOrdersProductsList1.vue' /* webpackChunkName: "components/last-orders-products-list1" */).then(c => c.default || c),
      LazyOrdersProducts: () => import('../client/components/ordersProducts/OrdersProducts.vue' /* webpackChunkName: "components/orders-products" */).then(c => c.default || c),
      LazyOrdersProductsList1: () => import('../client/components/ordersProducts/OrdersProductsList1.vue' /* webpackChunkName: "components/orders-products-list1" */).then(c => c.default || c),
      LazyPayments1: () => import('../client/components/payments/Payments1.vue' /* webpackChunkName: "components/payments1" */).then(c => c.default || c),
      LazyPaymentsList: () => import('../client/components/payments/PaymentsList.vue' /* webpackChunkName: "components/payments-list" */).then(c => c.default || c),
      LazyPresentation1: () => import('../client/components/presentation/Presentation1.vue' /* webpackChunkName: "components/presentation1" */).then(c => c.default || c),
      LazyPresentationOption: () => import('../client/components/presentation/PresentationOption.vue' /* webpackChunkName: "components/presentation-option" */).then(c => c.default || c),
      LazyPresentationOptionControls: () => import('../client/components/presentation/PresentationOptionControls.vue' /* webpackChunkName: "components/presentation-option-controls" */).then(c => c.default || c),
      LazyPresentationOptionCustomPO: () => import('../client/components/presentation/PresentationOptionCustomPO.vue' /* webpackChunkName: "components/presentation-option-custom-p-o" */).then(c => c.default || c),
      LazyPresentationOptionPO: () => import('../client/components/presentation/PresentationOptionPO.vue' /* webpackChunkName: "components/presentation-option-p-o" */).then(c => c.default || c),
      LazyPresentationView: () => import('../client/components/presentations/PresentationView.vue' /* webpackChunkName: "components/presentation-view" */).then(c => c.default || c),
      LazyPresentationView1: () => import('../client/components/presentations/PresentationView1.vue' /* webpackChunkName: "components/presentation-view1" */).then(c => c.default || c),
      LazyProductCardButton: () => import('../client/components/productCard/ProductCardButton.vue' /* webpackChunkName: "components/product-card-button" */).then(c => c.default || c),
      LazyProductCardButtonsList: () => import('../client/components/productCard/ProductCardButtonsList.vue' /* webpackChunkName: "components/product-card-buttons-list" */).then(c => c.default || c),
      LazyProductCardInfo: () => import('../client/components/productCard/ProductCardInfo.vue' /* webpackChunkName: "components/product-card-info" */).then(c => c.default || c),
      LazyButtonsSection: () => import('../client/components/productView/ButtonsSection.vue' /* webpackChunkName: "components/buttons-section" */).then(c => c.default || c),
      LazyDocumentsSection: () => import('../client/components/productView/DocumentsSection.vue' /* webpackChunkName: "components/documents-section" */).then(c => c.default || c),
      LazyInventoryStatus: () => import('../client/components/productView/InventoryStatus.vue' /* webpackChunkName: "components/inventory-status" */).then(c => c.default || c),
      LazyIsCloseout: () => import('../client/components/productView/IsCloseout.vue' /* webpackChunkName: "components/is-closeout" */).then(c => c.default || c),
      LazyPartsColor: () => import('../client/components/productView/PartsColor.vue' /* webpackChunkName: "components/parts-color" */).then(c => c.default || c),
      LazyPartsColors: () => import('../client/components/productView/PartsColors.vue' /* webpackChunkName: "components/parts-colors" */).then(c => c.default || c),
      LazyProductAttributes: () => import('../client/components/productView/ProductAttributes.vue' /* webpackChunkName: "components/product-attributes" */).then(c => c.default || c),
      LazyProductDecoration: () => import('../client/components/productView/ProductDecoration.vue' /* webpackChunkName: "components/product-decoration" */).then(c => c.default || c),
      LazyProductDecorationPartsSelect: () => import('../client/components/productView/ProductDecorationPartsSelect.vue' /* webpackChunkName: "components/product-decoration-parts-select" */).then(c => c.default || c),
      LazyProductDecorationSelect: () => import('../client/components/productView/ProductDecorationSelect.vue' /* webpackChunkName: "components/product-decoration-select" */).then(c => c.default || c),
      LazyProductDescriptions: () => import('../client/components/productView/ProductDescriptions.vue' /* webpackChunkName: "components/product-descriptions" */).then(c => c.default || c),
      LazyProductFeatures: () => import('../client/components/productView/ProductFeatures.vue' /* webpackChunkName: "components/product-features" */).then(c => c.default || c),
      LazyProductFomDiscontinued: () => import('../client/components/productView/ProductFomDiscontinued.vue' /* webpackChunkName: "components/product-fom-discontinued" */).then(c => c.default || c),
      LazyProductHeader: () => import('../client/components/productView/ProductHeader.vue' /* webpackChunkName: "components/product-header" */).then(c => c.default || c),
      LazyProductHeaderDetails: () => import('../client/components/productView/ProductHeaderDetails.vue' /* webpackChunkName: "components/product-header-details" */).then(c => c.default || c),
      LazyProductImage: () => import('../client/components/productView/ProductImage.vue' /* webpackChunkName: "components/product-image" */).then(c => c.default || c),
      LazyProductLastReviews: () => import('../client/components/productView/ProductLastReviews.vue' /* webpackChunkName: "components/product-last-reviews" */).then(c => c.default || c),
      LazyProductLocationDecorations: () => import('../client/components/productView/ProductLocationDecorations.vue' /* webpackChunkName: "components/product-location-decorations" */).then(c => c.default || c),
      LazyProductPrices: () => import('../client/components/productView/ProductPrices.vue' /* webpackChunkName: "components/product-prices" */).then(c => c.default || c),
      LazyProductShippingCalculator: () => import('../client/components/productView/ProductShippingCalculator.vue' /* webpackChunkName: "components/product-shipping-calculator" */).then(c => c.default || c),
      LazyProductView1: () => import('../client/components/productView/ProductView1.vue' /* webpackChunkName: "components/product-view1" */).then(c => c.default || c),
      LazyRecentlyViewed1: () => import('../client/components/productView/RecentlyViewed1.vue' /* webpackChunkName: "components/recently-viewed1" */).then(c => c.default || c),
      LazyRecommended: () => import('../client/components/productView/Recommended.vue' /* webpackChunkName: "components/recommended" */).then(c => c.default || c),
      LazySubPartsColors: () => import('../client/components/productView/SubPartsColors.vue' /* webpackChunkName: "components/sub-parts-colors" */).then(c => c.default || c),
      LazyProducts1: () => import('../client/components/products/Products1.vue' /* webpackChunkName: "components/products1" */).then(c => c.default || c),
      LazyLargeQuote1: () => import('../client/components/quote/LargeQuote1.vue' /* webpackChunkName: "components/large-quote1" */).then(c => c.default || c),
      LazyQuickQuote1: () => import('../client/components/quote/QuickQuote1.vue' /* webpackChunkName: "components/quick-quote1" */).then(c => c.default || c),
      LazyQuote1: () => import('../client/components/quote/Quote1.vue' /* webpackChunkName: "components/quote1" */).then(c => c.default || c),
      LazyReadMore1: () => import('../client/components/read-more/ReadMore1.vue' /* webpackChunkName: "components/read-more1" */).then(c => c.default || c),
      LazyReferenceField: () => import('../client/components/referenceField/ReferenceField.vue' /* webpackChunkName: "components/reference-field" */).then(c => c.default || c),
      LazyReferenceFieldCell: () => import('../client/components/referenceField/ReferenceFieldCell.vue' /* webpackChunkName: "components/reference-field-cell" */).then(c => c.default || c),
      LazyReorderProductItem: () => import('../client/components/reorderView/ReorderProductItem.vue' /* webpackChunkName: "components/reorder-product-item" */).then(c => c.default || c),
      LazyReorderView1: () => import('../client/components/reorderView/ReorderView1.vue' /* webpackChunkName: "components/reorder-view1" */).then(c => c.default || c),
      LazyResetPassword1: () => import('../client/components/resetpassword/ResetPassword1.vue' /* webpackChunkName: "components/reset-password1" */).then(c => c.default || c),
      LazyProductRating: () => import('../client/components/review/ProductRating.vue' /* webpackChunkName: "components/product-rating" */).then(c => c.default || c),
      LazyProductRatingGraph: () => import('../client/components/review/ProductRatingGraph.vue' /* webpackChunkName: "components/product-rating-graph" */).then(c => c.default || c),
      LazyProductReviewsList1: () => import('../client/components/review/ProductReviewsList1.vue' /* webpackChunkName: "components/product-reviews-list1" */).then(c => c.default || c),
      LazyReviewForm: () => import('../client/components/review/ReviewForm.vue' /* webpackChunkName: "components/review-form" */).then(c => c.default || c),
      LazyReviewOrderProduct1: () => import('../client/components/review/ReviewOrderProduct1.vue' /* webpackChunkName: "components/review-order-product1" */).then(c => c.default || c),
      LazyReviewPreview: () => import('../client/components/review/ReviewPreview.vue' /* webpackChunkName: "components/review-preview" */).then(c => c.default || c),
      LazyReviewsList: () => import('../client/components/review/ReviewsList.vue' /* webpackChunkName: "components/reviews-list" */).then(c => c.default || c),
      LazySampleRequest1: () => import('../client/components/sample/SampleRequest1.vue' /* webpackChunkName: "components/sample-request1" */).then(c => c.default || c),
      LazySampleItemsAdded1: () => import('../client/components/sampleitemsadded/SampleItemsAdded1.vue' /* webpackChunkName: "components/sample-items-added1" */).then(c => c.default || c),
      LazySearchBlogsResults1: () => import('../client/components/search/SearchBlogsResults1.vue' /* webpackChunkName: "components/search-blogs-results1" */).then(c => c.default || c),
      LazySearchFacetsResults1: () => import('../client/components/search/SearchFacetsResults1.vue' /* webpackChunkName: "components/search-facets-results1" */).then(c => c.default || c),
      LazySearchSuppliersResults1: () => import('../client/components/search/SearchSuppliersResults1.vue' /* webpackChunkName: "components/search-suppliers-results1" */).then(c => c.default || c),
      LazySearchThemesResults1: () => import('../client/components/search/SearchThemesResults1.vue' /* webpackChunkName: "components/search-themes-results1" */).then(c => c.default || c),
      LazySignin1: () => import('../client/components/signin/Signin1.vue' /* webpackChunkName: "components/signin1" */).then(c => c.default || c),
      LazySigninWithSSO: () => import('../client/components/signin/SigninWithSSO.vue' /* webpackChunkName: "components/signin-with-s-s-o" */).then(c => c.default || c),
      LazySignout1: () => import('../client/components/signout/Signout1.vue' /* webpackChunkName: "components/signout1" */).then(c => c.default || c),
      LazySkeleton: () => import('../client/components/skeleton/index.vue' /* webpackChunkName: "components/skeleton" */).then(c => c.default || c),
      LazySpecialCard: () => import('../client/components/special/SpecialCard.vue' /* webpackChunkName: "components/special-card" */).then(c => c.default || c),
      LazySpecialsList: () => import('../client/components/special/SpecialsList.vue' /* webpackChunkName: "components/specials-list" */).then(c => c.default || c),
      LazyFacebookDelete1: () => import('../client/components/sso/FacebookDelete1.vue' /* webpackChunkName: "components/facebook-delete1" */).then(c => c.default || c),
      LazySingleSignOn1: () => import('../client/components/sso/SingleSignOn1.vue' /* webpackChunkName: "components/single-sign-on1" */).then(c => c.default || c),
      LazySupplier1: () => import('../client/components/supplier/Supplier1.vue' /* webpackChunkName: "components/supplier1" */).then(c => c.default || c),
      LazyTrackShipment1: () => import('../client/components/trackShipment/TrackShipment1.vue' /* webpackChunkName: "components/track-shipment1" */).then(c => c.default || c),
      LazyTrackShipmentArticle: () => import('../client/components/trackShipment/TrackShipmentArticle.vue' /* webpackChunkName: "components/track-shipment-article" */).then(c => c.default || c),
      LazyTrackShipmentDetails: () => import('../client/components/trackShipment/TrackShipmentDetails.vue' /* webpackChunkName: "components/track-shipment-details" */).then(c => c.default || c),
      LazyTrackShipmentItemInfo: () => import('../client/components/trackShipment/TrackShipmentItemInfo.vue' /* webpackChunkName: "components/track-shipment-item-info" */).then(c => c.default || c),
      LazyGlobalSearch: () => import('../client/components/typeahead/GlobalSearch.vue' /* webpackChunkName: "components/global-search" */).then(c => c.default || c),
      LazyUploadArtworksList: () => import('../client/components/uploadButton/UploadArtworksList.vue' /* webpackChunkName: "components/upload-artworks-list" */).then(c => c.default || c),
      LazyUploadButton: () => import('../client/components/uploadButton/UploadButton.vue' /* webpackChunkName: "components/upload-button" */).then(c => c.default || c),
      LazyUploadFileListItem: () => import('../client/components/uploadButton/UploadFileListItem.vue' /* webpackChunkName: "components/upload-file-list-item" */).then(c => c.default || c),
      LazyUploadFilesList: () => import('../client/components/uploadButton/UploadFilesList.vue' /* webpackChunkName: "components/upload-files-list" */).then(c => c.default || c),
      LazyUploadFromLink: () => import('../client/components/uploadButton/UploadFromLink.vue' /* webpackChunkName: "components/upload-from-link" */).then(c => c.default || c),
      LazyUploadModalButton: () => import('../client/components/uploadButton/UploadModalButton.vue' /* webpackChunkName: "components/upload-modal-button" */).then(c => c.default || c),
      LazyCustomerArtworkProofs: () => import('../client/components/account/Proofs/CustomerArtworkProofs.vue' /* webpackChunkName: "components/customer-artwork-proofs" */).then(c => c.default || c),
      LazyProofs: () => import('../client/components/account/Proofs/Index.vue' /* webpackChunkName: "components/proofs" */).then(c => c.default || c),
      LazyProofsList: () => import('../client/components/account/Proofs/ProofsList.vue' /* webpackChunkName: "components/proofs-list" */).then(c => c.default || c),
      LazyArtwork: () => import('../client/components/account/admin/Artwork.vue' /* webpackChunkName: "components/artwork" */).then(c => c.default || c),
      LazyCustomers: () => import('../client/components/account/admin/Customers.vue' /* webpackChunkName: "components/customers" */).then(c => c.default || c),
      LazyReview: () => import('../client/components/account/admin/Review.vue' /* webpackChunkName: "components/review" */).then(c => c.default || c),
      LazyBlogCategoriesList: () => import('../client/components/blog/BlogPostList/BlogCategoriesList.vue' /* webpackChunkName: "components/blog-categories-list" */).then(c => c.default || c),
      LazyBlogPostCard: () => import('../client/components/blog/BlogPostList/BlogPostCard.vue' /* webpackChunkName: "components/blog-post-card" */).then(c => c.default || c),
      LazyBlogPostList: () => import('../client/components/blog/BlogPostList/index.vue' /* webpackChunkName: "components/blog-post-list" */).then(c => c.default || c),
      LazyCloseout: () => import('../client/components/categories/closeout/index.vue' /* webpackChunkName: "components/closeout" */).then(c => c.default || c),
      LazyNoMinimum: () => import('../client/components/categories/no-minimum/index.vue' /* webpackChunkName: "components/no-minimum" */).then(c => c.default || c),
      LazyProductCheckInventory: () => import('../client/components/productView/ProductInventory/ProductCheckInventory.vue' /* webpackChunkName: "components/product-check-inventory" */).then(c => c.default || c),
      LazyProductInventoryItem: () => import('../client/components/productView/ProductInventory/ProductInventoryItem.vue' /* webpackChunkName: "components/product-inventory-item" */).then(c => c.default || c),
      LazyProductInventoryLocation: () => import('../client/components/productView/ProductInventory/ProductInventoryLocation.vue' /* webpackChunkName: "components/product-inventory-location" */).then(c => c.default || c),
      LazyProductMediaPreview: () => import('../client/components/productView/media/ProductMediaPreview.vue' /* webpackChunkName: "components/product-media-preview" */).then(c => c.default || c),
      LazyProductMediaPreviewFile: () => import('../client/components/productView/media/ProductMediaPreviewFile.vue' /* webpackChunkName: "components/product-media-preview-file" */).then(c => c.default || c),
      LazyCustomerArtworkProofView1: () => import('../client/components/proofs/proofView/CustomerArtworkProofView1.vue' /* webpackChunkName: "components/customer-artwork-proof-view1" */).then(c => c.default || c),
      LazyProofView1: () => import('../client/components/proofs/proofView/ProofView1.vue' /* webpackChunkName: "components/proof-view1" */).then(c => c.default || c),
      LazyOrderApprovals: () => import('../client/components/account/admin/OrderApprovals/index.vue' /* webpackChunkName: "components/order-approvals" */).then(c => c.default || c),
      LazyReporting: () => import('../client/components/account/admin/Reporting/index.vue' /* webpackChunkName: "components/reporting" */).then(c => c.default || c),
      LazyAddressInfo: () => import('../client/components/account/admin/StoreAddresses/AddressInfo.vue' /* webpackChunkName: "components/address-info" */).then(c => c.default || c),
      LazyImportShipping: () => import('../client/components/account/admin/StoreAddresses/ImportShipping.vue' /* webpackChunkName: "components/import-shipping" */).then(c => c.default || c),
      LazyShippingImportCSV: () => import('../client/components/account/admin/StoreAddresses/ShippingImportCSV.vue' /* webpackChunkName: "components/shipping-import-c-s-v" */).then(c => c.default || c),
      LazyStoreAddressFormInput: () => import('../client/components/account/admin/StoreAddresses/StoreAddressFormInput.vue' /* webpackChunkName: "components/store-address-form-input" */).then(c => c.default || c),
      LazyStoreAddressFormModal: () => import('../client/components/account/admin/StoreAddresses/StoreAddressFormModal.vue' /* webpackChunkName: "components/store-address-form-modal" */).then(c => c.default || c),
      LazyStoreAddresses: () => import('../client/components/account/admin/StoreAddresses/index.vue' /* webpackChunkName: "components/store-addresses" */).then(c => c.default || c),
      LazyList: () => import('../client/components/account/admin/Reporting/View/List.vue' /* webpackChunkName: "components/list" */).then(c => c.default || c),
      LazyReportingViewFormModal: () => import('../client/components/account/admin/Reporting/View/ReportingViewFormModal.vue' /* webpackChunkName: "components/reporting-view-form-modal" */).then(c => c.default || c)
}

const prefixes = ["reporting-form","reporting-form-settings","configureproduct","nuxt"]

inject("nuxtDynamic", { loaders, prefixes });
};
