const ordersApiFactory = (axios) => ({
  getOrderPayments: async (orderId) => (await axios.get(`/orders/${orderId}/order-payments`)).data,
  getCustomerOrders: (customerId) => axios.get(`/orders/by-customer/${customerId}`),
  getCustomerOrderPO: async (orderId, customerId) =>
    (await axios.get(`/orders/${orderId}/customer/${customerId}`)).data,
  getOneOrder: (orderId) => axios.get(`/orders/${orderId}/byId`),
  processPayment: (paymentData) => axios.post('/orders/processpayment', paymentData),
  createOrderDetail: (orderDetail, orderId) => axios.post(`/orders/details/${orderId}`, orderDetail),
  updateOrderDetail: (orderDetail, orderId) => axios.put(`/orders/details/${orderId}`, orderDetail),
  getOneOrderDetail: (orderId, detailId) => axios.get(`/orders/${orderId}/details/${detailId}`),
  configureProduct: (configurationData) => axios.post('/orders/configureproduct', configurationData),
  updateOrderStatus: (orderId, status, additionalData) =>
    axios.patch(`/orders/${orderId}/status`, { status, ...additionalData }),
  updatePurchaseOrderStatus: (orderId, poId, status) =>
    axios.patch(`/orders/${orderId}/purchaseOrders/${poId}/status`, { status }),
  createOrderFollowUp: (followUp) => axios.post(`/orders/${followUp.orderId}/followup`, followUp),
  updatePurchaseOrdersTotals: (data) => axios.put(`/orders/${data.orderId}/purchase-orders/totals`, data),
  changeProofStatus: async (data) => (await axios.put(`/virtual-proofs/${data._id}/change-status`, data)).data,
  checkProofAccess: async (arttoken) =>
    (
      await axios.get(`/virtual-proofs-to-approve/${arttoken}/exist`, {
        headers: {
          arttoken,
        },
      })
    ).data,

  shipmentEstimate: async (destination, orderId, options) => {
    try {
      return (
        await axios.post('/shipments/order/estimate', {
          ...options,
          orderId,
          destination,
          useCustomerDestination: true,
        })
      ).data;
    } catch (e) {
      throw e.response;
    }
  },
  taxEstimate: async (destination, orderId, options) => {
    try {
      return (await axios.post(`/orders/tax/calculate`, { orderId, destination, ...options })).data;
    } catch (e) {
      throw e.response;
    }
  },
  getOrderTrackShipment: async (orderId) => (await axios.get(`/orders/${orderId}/track-shipment`)).data,
  copyOrderToCart: async (orderId, data) => (await axios.post(`/orders/${orderId}/copy-to-cart`, data)).data,
  getEmails: async (orderId, firstNameFrom) =>
    (await axios.get(`/orders/${orderId}/emails`, { params: { firstNameFrom } })).data,
});

export { ordersApiFactory };
