import { encodeOpts as paramsSerializer } from '@/utils/encode-opts';

const productsApiFactory = (axios) => ({
  getProduct: (supplierCode, productId) => axios.get(`/product/${supplierCode}/${productId}`),
  getProducts: (products) => axios.get(`/products?products=${products}`),
  getProductById: (productId, supplierCode, context) =>
    ((context && context.$axios) || axios).get(`/products/${productId}?withoutParts=1&supplierCode=${supplierCode}`),
  getProductBySlug: async (slug, params = {}, context) =>
    (await ((context && context.$axios) || axios).get(`/products/${slug}`, { params, paramsSerializer })).data,
  getProductStoreBySlug: async (slug, params = {}, context) =>
    (await ((context && context.$axios) || axios).post(`/product-store/${slug}`, params)).data,
  loadAllRemainParts: async (slug, params = {}) =>
    (await axios.post(`/products/${slug}/parts`, { params, paramsSerializer })).data,
  loadUniqueParts: async (slug, params = {}) =>
    (await axios.get(`/products/${slug}/parts/by-unique-color`, { params, paramsSerializer })).data,
  checkPrices: async (_id, params = {}) => (await axios.post(`/products/${_id}/parts/check-prices`, params)).data,
  checkMappedDecorationPrices: async (_id, params = {}) =>
    (await axios.post(`/products/${_id}/parts/check-mapped-decoration-prices`, params)).data,
  getLatestProductInventory: async (_id, params = {}) => (await axios.get(`/products/${_id}/inventory`, params)).data,
  getProductReviewsStatistic: async (_id, storeId) =>
    (await axios.get(`/products/${_id}/reviews/statistic`, { params: { storeId }, paramsSerializer })).data,
  getProductReviews: async (_id, params) =>
    (await axios.get(`/products/${_id}/reviews`, { params, paramsSerializer })).data,
  getProductLocations: async (_id, params) =>
    (await axios.get(`/products/${_id}/locations`, { params, paramsSerializer })).data,
  productsCheck: async (products) => (await axios.post(`/stores/products/check-decoration-prices`, { products })).data,
  getPartWithAllColors: async (productId, partId) =>
    (await axios.get(`/products/${productId}/parts/${partId}/all-colors`)).data,
});

export { productsApiFactory };
