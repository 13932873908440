import { generateFakeReq } from './utils';

const storePagesInfoFactory = (axios) => ({
  async getStorePageHeadTags(context, urlSlug, additionalSlug, options = {}) {
    const body = {
      additionalSlug,
      ...(options || {}),
      urlSlug: urlSlug || 'undefined',
      applyVersion: true
    };
    const { storeUrl } = context.store.getters.storeInformation;
    if (process.server && !process.env.IS_LOCAL) {
      const params = { storeUrl };
      const fakeReq = await generateFakeReq(context, { body, params });
      const { getStorePageHeadTags } = require('@mtntop/utils/backend/info/store-pages');
      const result = await getStorePageHeadTags(fakeReq);
      return JSON.parse(JSON.stringify(result || null));
    } else {
      return (await axios.post(`/stores/${storeUrl}/headtags`, body)).data;
    }
  },
  async getStorePageInformation(
    context,
    { urlSlug, additionalSlug, categorySlug, customer, query = {}, params = {}, ...remainOptions }
  ) {
    const body = {
      additionalSlug,
      query,
      customer,
      categorySlug,
      urlSlug: urlSlug || 'undefined',
      params: { additionalSlug, urlSlug: urlSlug || 'undefined' },
      ...params,
      ...remainOptions,
    };
    const { storeUrl } = context.store.getters.storeInformation;
    if (process.server && !process.env.IS_LOCAL) {
      const params = { storeUrl };
      const fakeReq = await generateFakeReq(context, { body, params });
      const { getStorePageInformation } = require('@mtntop/utils/backend/info/store-pages');
      const result = await getStorePageInformation(fakeReq);
      return JSON.parse(JSON.stringify(result || null));
    } else {
      return (await axios.post(`/stores/${storeUrl}/pages`, body)).data;
    }
  },
});

export { storePagesInfoFactory };
