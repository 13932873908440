export const encodeOpts = (opts) => {
  let str = '';
  for (let key in opts) {
    if (str !== '') {
      str += '&';
    }
    str += key + '=' + encodeURIComponent(JSON.stringify(opts[key]));
  }
  return str;
};
