export const state = () => ({
  boxVisible: false,
  cartVisible: false,
  sidebarCartType: '',
});

export const getters = {
  boxSideBarVisible: (state) => state.boxVisible,
  cartSideBarVisible: (state) => state.cartVisible,
  sidebarCartType: (state) => state.sidebarCartType,
};

export const actions = {
  async TOGGLE_CART_SIDEBAR({ commit, ...r }, payload) {
    commit('TOGGLE_CART_SIDEBAR', payload);
  },
  async HIDE_CART_SIDEBAR({ commit, ...r }, payload) {
    commit('HIDE_CART_SIDEBAR', payload);
  },
};
const checkOverflow = (visible) => {
  const body = document.getElementsByTagName('body')[0];
  if (visible) {
    body.classList.add('overflow-hidden');
  } else {
    body.classList.remove('overflow-hidden');
  }
};
export const mutations = {
  TOGGLE_BOX_SIDEBAR(state) {
    state.boxVisible = !state.boxVisible;
    checkOverflow(state.boxVisible);
  },
  TOGGLE_CART_SIDEBAR(state, cartType) {
    state.cartVisible = !state.cartVisible;
    state.sidebarCartType = cartType;
    checkOverflow(state.cartVisible);
  },
  HIDE_CART_SIDEBAR(state) {
    state.cartVisible = false;
    state.sidebarCartType = null;
    document.body.className = '';
  },
};
