const state = () => ({
  reports: {
    dateRange: {},
  },
});

const getters = {
  reportsDateRange: (state) => state.reports.dateRange,
};

const mutations = {
  SET_REPORTS_DATE_RANGE(state, range) {
    state.reports.dateRange = range;
  },
};

export default {
  state,
  getters,
  mutations,
};
