import Vue from 'vue';

// import ProductPartQuantityTable from '@mtntop/utils/src/mixins/productDiscountMixin';
// import VueHtmlToPaper from 'vue-html-to-paper';
// import Vue2TouchEvents from 'vue2-touch-events';
import VueLazyload from 'vue-lazyload';
import './prototypes';
// import 'bootstrap-vue/dist/bootstrap-vue.css';

// Vue.component('ProductPartQuantityTable', ProductPartQuantityTable);

// const htmlToPaperOptions = {
//   name: '_blank',
//   specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
//   styles: ['https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css'],
// };

Vue.use(VueLazyload, {
  error: 'img/loading-img.png',
  loading: 'img/loading-img.png',
});
// Vue.use(Vue2TouchEvents);
// Vue.use(ProductPartQuantityTable); //ProductPartQuantityTable,FormConfigureSizes,roundCeil
// Vue.use(VueHtmlToPaper, htmlToPaperOptions);
