import { generateFakeReq } from './utils';

const productsInfoFactory = (axios) => ({
  async getProductBySlug(context, slugOrId, body) {
    if (process.server && !process.env.IS_LOCAL && 0) {
      const params = { slugOrId };
      const fakeReq = await generateFakeReq(context, { body, params });
      const { getProductBySlug } = require('@mtntop/utils/backend/info/products');
      const result = await getProductBySlug(fakeReq);
      return JSON.parse(JSON.stringify(result || null));
    } else {
      return (await axios.post(`/product-store/${slugOrId}`, body)).data;
    }
  },
});

export { productsInfoFactory };
