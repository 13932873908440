const storesInfoFactory = (axios) => ({
  async getStoreInformation(storeUrl, params) {
    if (process.server && !process.env.IS_LOCAL) {
      const { getStoreInformation } = require('@mtntop/utils/backend/info/stores');
      const result = await getStoreInformation(storeUrl, params);
      return JSON.parse(JSON.stringify(result || null));
    } else {
      return (await axios.get(`/stores/information/${storeUrl}?referer=${params.referer}`)).data;
    }
  },
});

export { storesInfoFactory };
