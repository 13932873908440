const middleware = {}

middleware['authenticated'] = require('../client/middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['cacheHeader'] = require('../client/middleware/cacheHeader.js')
middleware['cacheHeader'] = middleware['cacheHeader'].default || middleware['cacheHeader']

middleware['checkAuth'] = require('../client/middleware/checkAuth.js')
middleware['checkAuth'] = middleware['checkAuth'].default || middleware['checkAuth']

middleware['notAuthenticated'] = require('../client/middleware/notAuthenticated.js')
middleware['notAuthenticated'] = middleware['notAuthenticated'].default || middleware['notAuthenticated']

middleware['seo'] = require('../client/middleware/seo.js')
middleware['seo'] = middleware['seo'].default || middleware['seo']

export default middleware
