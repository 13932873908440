import { getIpInfo } from '@/api/ipdata';
import { CUSTOMER_DEVICE_SOURCE } from '@/utils/constants';
import { encodeOpts as paramsSerializer } from '@/utils/encode-opts';

const customersApiFactory = (axios) => {
  const self = {
    createCustomer: (customer) => axios.post('/customers', customer),
    updateCustomer: (customer) => axios.put(`/customers/${customer._id}`, customer),
    getOneCustomer: async (customerId) => (await axios.get(`/customers/${customerId}`)).data,
    updateBillingAddress: (billing) => axios.put('/customers/billings', billing),
    deleteBillingAddress: (billingId) => axios.delete(`/customers/billings/${billingId}`),
    updateCustomerPhone: (phone) => axios.put('/customers/phones', phone),
    changeCustomerArtworkProof: async (customerArtworkProof) =>
      (
        await axios.put(
          `/customer-artwork/approve-request/${customerArtworkProof._id}/token/${customerArtworkProof.token_access}`,
          customerArtworkProof
        )
      ).data,
    createCustomerPhone: (phone) => axios.post('/customers/phones', phone),
    createCustomerPhones: (data) => axios.post('/customers/phones/all', data),
    getCustomerArtworks: async (customerId) => (await axios.get(`/customers/${customerId}/artwork`)).data,
    addCustomerArtwork: async (customerId, formData) =>
      (
        await axios.post(`/customers/${customerId}/artwork`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      ).data,
    deleteCustomerArtwork: ({ artworkId, customerId }) => axios.delete(`/customers/${customerId}/artwork/${artworkId}`),
    addCustomerDevice: async (customer, data) => {
      try {
        const customerId = customer._id ? customer._id : customer;
        if (customerId) {
          const info = await getIpInfo();
          return await axios.post(`/customers/${customerId}/devices`, { ...info, ...(data || {}) });
        }
      } catch (e) {}
    },
    sendRegistrations: async (data) => {
      try {
        return (await axios.post(`/customers/send-registrations`, { ...(data || {}) })).data;
      } catch (e) {}
    },
    getCustomerOrdersProducts: async (customerId, storeId, params) => {
      return (
        await axios.get(`/customers/${customerId}/stores/${storeId}/orders/products`, { params, paramsSerializer })
      ).data;
    },
    getCustomerOrderProduct: async (customerId, orderId, productId) => {
      return (await axios.get(`/customers/${customerId}/orders/${orderId}/products/${productId}`)).data;
    },
    getCustomerReviews: async (customerId, storeId, params) => {
      return (await axios.get(`/customers/${customerId}/stores/${storeId}/reviews`, { params, paramsSerializer })).data;
    },
    getCustomerByReviewsToken: async (token) => {
      return (await axios.get(`/customers/by-reviews-token/${token}`)).data;
    },
    getCustomerReorderToken: async (token) => {
      return (
        await axios.get(`/customers/reorder-token/${token}`, {
          headers: {
            apitoken: token,
          },
        })
      ).data;
    },
  };

  Object.assign(self, {
    addCustomerCreateAccountDevice: (customerId) =>
      self.addCustomerDevice(customerId, { source: CUSTOMER_DEVICE_SOURCE.CREATE_ACCOUNT }),
    addCustomerCheckoutDevice: (order) =>
      self.addCustomerDevice(order.customerId, { source: CUSTOMER_DEVICE_SOURCE.CHECKOUT, orderId: order._id }),
  });
  return self;
};

export { customersApiFactory };
