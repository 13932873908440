import { encodeOpts as paramsSerializer } from '@/utils/encode-opts';

const suppliersApiFactory = (axios) => ({
  getSuppliers: async (params = {}) => {
    return (await axios.get(`/suppliers`, { params, paramsSerializer })).data;
  },
  async suppliersSearch(query, esSearch = false, limit = 5) {
    const params = { ...query, limit, withProducts: true, isActive: true, esSearch };
    return this.getSuppliers(params);
  },
});

export { suppliersApiFactory };
