import { encodeOpts as paramsSerializer } from '@/utils/encode-opts';

const creditAllowancesApiFactory = (axios) => ({
  getCreditsAllowances: async (params = {}) =>
    (await axios.get(`/credits-allowances`, { params, paramsSerializer })).data,
  createCreditAllowance: async (data) => (await axios.post('/credits-allowances', data)).data,
  deleteCreditAllowance: async (id) => (await axios.delete(`/credits-allowances/${id}`)).data,
  updateCreditAllowance: async (data) => (await axios.put(`/credits-allowances/${data._id}`, data)).data,
});

export { creditAllowancesApiFactory };
