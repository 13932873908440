import { encodeOpts as paramsSerializer } from '@/utils/encode-opts';

const walkthroughsApiFactory = (axios) => ({
  getPageWalkthrough: async (params) => {
    return (
      await axios.get(`/walkthrough/by-page`, {
        params: params,
        paramsSerializer,
      })
    ).data;
  },
});

export { walkthroughsApiFactory };
