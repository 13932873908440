import { productDecorationsMixin } from './productDecorations';

export default {
  mixins: [productDecorationsMixin],
  methods: {
    async select(part) {
      try {
        this.selectedDecorationProduct.selectedPart = part._id;

        this.$emit('change', this.selectedDecorationProduct);
        if (!this.$attrs.isConfigurable) {
          await this.onChangeProductDecoration(this.selectedDecorationProduct);
        }
      } catch (e) {
        console.log(e);
      }
    },
    selectedDecorationProductPartItem(decor) {
      const _selected = this.selectedDecorationProductItem(decor);
      return _selected && _selected.parts.find((e) => e._id === _selected.selectedPart);
    },
    selectedDecorationProductItem(decor) {
      return this.productData.decorationProducts.find(
        (e) =>
          (e.selectedDecoration && e.selectedDecoration._id === decor._id) ||
          e.locations.find((e) => e.decorations.map((e) => e._id).includes(decor._id))
      );
    },

    selectedSetupChargeItem(decor) {
      let setupPrice = decor?.selectedCharge?.price || 0;

      if (decor) {
        if (decor.selectedCharge) {
          const selectedPart = this.selectedDecorationProductPartItem(decor);
          if (selectedPart) {
            const keysV = ['xMinQty', 'yMinQty', 'xUom', 'yUom'];

            const price = decor.selectedCharge.pricing.find(
              (price) =>
                keysV.filter((e, index) => selectedPart.charge_prices?.find((pr) => pr[e] === price[keysV[index]]))
                  .length === keysV.length
            );
            if (price) setupPrice = price.price;
          }
        }
      }
      return (setupPrice && parseFloat(setupPrice)) || 0;
    },
  },
  computed: {
    selectedDecorationProductPart() {
      const _selected = this.selectedDecorationProduct;
      return ((_selected && _selected.parts) || []).find((e) => e._id === _selected.selectedPart);
    },
    selectedDecorationProduct() {
      return this.productData.decorationProducts.find(
        (e) =>
          (e.selectedDecoration && e.selectedDecoration._id === this.selectedDecoration._id) ||
          e.locations.find((e) => e.decorations.map((e) => e._id).includes(this.selectedDecoration._id))
      );
    },
  },
};
