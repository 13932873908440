import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1c6738ca = () => interopDefault(import('../client/pages/cart/index.vue' /* webpackChunkName: "" */))
const _09f991d6 = () => interopDefault(import('../client/pages/products/no-minimum/_.vue' /* webpackChunkName: "" */))
const _6d256529 = () => interopDefault(import('../client/pages/products/closeout/_.vue' /* webpackChunkName: "" */))
const _62f5f7a8 = () => interopDefault(import('../client/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _974df64a = () => interopDefault(import('../client/pages/cartitemsadded/index.vue' /* webpackChunkName: "pages/cartitemsadded/index" */))
const _07d61506 = () => interopDefault(import('../client/pages/changepassword/index.vue' /* webpackChunkName: "pages/changepassword/index" */))
const _1f340ff7 = () => interopDefault(import('../client/pages/customer-artwork-proof/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/index" */))
const _6ae7ac20 = () => interopDefault(import('../client/pages/designs/index.vue' /* webpackChunkName: "pages/designs/index" */))
const _a5b831d2 = () => interopDefault(import('../client/pages/facets/index.vue' /* webpackChunkName: "pages/facets/index" */))
const _cea9f95c = () => interopDefault(import('../client/pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _3819e48e = () => interopDefault(import('../client/pages/forgotpassword/index.vue' /* webpackChunkName: "pages/forgotpassword/index" */))
const _c0597f58 = () => interopDefault(import('../client/pages/health.vue' /* webpackChunkName: "pages/health" */))
const _e762c87a = () => interopDefault(import('../client/pages/not-found/index.vue' /* webpackChunkName: "pages/not-found/index" */))
const _6231adff = () => interopDefault(import('../client/pages/proof/index.vue' /* webpackChunkName: "pages/proof/index" */))
const _2100bb82 = () => interopDefault(import('../client/pages/proofitemsadded/index.vue' /* webpackChunkName: "pages/proofitemsadded/index" */))
const _088f6912 = () => interopDefault(import('../client/pages/quoteitemsadded/index.vue' /* webpackChunkName: "pages/quoteitemsadded/index" */))
const _5b9b8625 = () => interopDefault(import('../client/pages/resetpassword/index.vue' /* webpackChunkName: "pages/resetpassword/index" */))
const _5e430336 = () => interopDefault(import('../client/pages/sampleitemsadded/index.vue' /* webpackChunkName: "pages/sampleitemsadded/index" */))
const _c53f74c6 = () => interopDefault(import('../client/pages/signin/index.vue' /* webpackChunkName: "pages/signin/index" */))
const _7cac04ec = () => interopDefault(import('../client/pages/signout/index.vue' /* webpackChunkName: "pages/signout/index" */))
const _9afc55bc = () => interopDefault(import('../client/pages/suppliers/index.vue' /* webpackChunkName: "pages/suppliers/index" */))
const _3ed9b322 = () => interopDefault(import('../client/pages/testy/index.vue' /* webpackChunkName: "pages/testy/index" */))
const _0f8693e0 = () => interopDefault(import('../client/pages/version.vue' /* webpackChunkName: "pages/version" */))
const _38a36a67 = () => interopDefault(import('../client/pages/version-app/index.vue' /* webpackChunkName: "pages/version-app/index" */))
const _d02c42c6 = () => interopDefault(import('../client/pages/cat/GeneralProductView.vue' /* webpackChunkName: "pages/cat/GeneralProductView" */))
const _0d254cee = () => interopDefault(import('../client/pages/orders/products/index.vue' /* webpackChunkName: "pages/orders/products/index" */))
const _0d2acade = () => interopDefault(import('../client/pages/products/utils.js' /* webpackChunkName: "pages/products/utils" */))
const _2a30e84c = () => interopDefault(import('../client/pages/sso/facebook/delete.vue' /* webpackChunkName: "pages/sso/facebook/delete" */))
const _02f39430 = () => interopDefault(import('../client/pages/review/order/_orderId/product/_productId.vue' /* webpackChunkName: "pages/review/order/_orderId/product/_productId" */))
const _4518748a = () => interopDefault(import('../client/pages/products/search/_.vue' /* webpackChunkName: "pages/products/search/_" */))
const _4c9c999e = () => interopDefault(import('../client/pages/blog/cat/_.vue' /* webpackChunkName: "pages/blog/cat/_" */))
const _26de8851 = () => interopDefault(import('../client/pages/approval/_orderId/index.vue' /* webpackChunkName: "pages/approval/_orderId/index" */))
const _701768f8 = () => interopDefault(import('../client/pages/artproof/_productSlug/index.vue' /* webpackChunkName: "pages/artproof/_productSlug/index" */))
const _14880149 = () => interopDefault(import('../client/pages/authors/_authorName/index.vue' /* webpackChunkName: "pages/authors/_authorName/index" */))
const _76708ecd = () => interopDefault(import('../client/pages/components/_componentId/index.vue' /* webpackChunkName: "pages/components/_componentId/index" */))
const _4aa2a808 = () => interopDefault(import('../client/pages/configureproduct/_productSlug/index.vue' /* webpackChunkName: "pages/configureproduct/_productSlug/index" */))
const _85f3513c = () => interopDefault(import('../client/pages/customer-artwork-proof/_id/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/_id/index" */))
const _6cbd7a1a = () => interopDefault(import('../client/pages/designer/_productSlug/index.vue' /* webpackChunkName: "pages/designer/_productSlug/index" */))
const _2f72f12b = () => interopDefault(import('../client/pages/designs/_slug/index.vue' /* webpackChunkName: "pages/designs/_slug/index" */))
const _e4c815dc = () => interopDefault(import('../client/pages/facets/_slug/index.vue' /* webpackChunkName: "pages/facets/_slug/index" */))
const _510d47b8 = () => interopDefault(import('../client/pages/largequote/_productSlug/index.vue' /* webpackChunkName: "pages/largequote/_productSlug/index" */))
const _6164cc0b = () => interopDefault(import('../client/pages/orders/_id/index.vue' /* webpackChunkName: "pages/orders/_id/index" */))
const _4b36d327 = () => interopDefault(import('../client/pages/payments/_orderId/index.vue' /* webpackChunkName: "pages/payments/_orderId/index" */))
const _d0baabcc = () => interopDefault(import('../client/pages/presentation/_orderId/index.vue' /* webpackChunkName: "pages/presentation/_orderId/index" */))
const _2d6d33ff = () => interopDefault(import('../client/pages/presentations/_id/index.vue' /* webpackChunkName: "pages/presentations/_id/index" */))
const _3f795394 = () => interopDefault(import('../client/pages/printinvoice/_orderId/index.vue' /* webpackChunkName: "pages/printinvoice/_orderId/index" */))
const _1062c64c = () => interopDefault(import('../client/pages/products/_slug/index.vue' /* webpackChunkName: "pages/products/_slug/index" */))
const _27149d2c = () => interopDefault(import('../client/pages/proof/_id/index.vue' /* webpackChunkName: "pages/proof/_id/index" */))
const _2972a7d6 = () => interopDefault(import('../client/pages/quickquote/_productSlug/index.vue' /* webpackChunkName: "pages/quickquote/_productSlug/index" */))
const _d98c563e = () => interopDefault(import('../client/pages/reorder/_id/index.vue' /* webpackChunkName: "pages/reorder/_id/index" */))
const _3b3e5c7b = () => interopDefault(import('../client/pages/sample/_productSlug/index.vue' /* webpackChunkName: "pages/sample/_productSlug/index" */))
const _19220a6e = () => interopDefault(import('../client/pages/sso/_service.vue' /* webpackChunkName: "pages/sso/_service" */))
const _5ec028c1 = () => interopDefault(import('../client/pages/cat/_slug/brand.vue' /* webpackChunkName: "pages/cat/_slug/brand" */))
const _08a06794 = () => interopDefault(import('../client/pages/orders/_id/track-shipment/index.vue' /* webpackChunkName: "pages/orders/_id/track-shipment/index" */))
const _7f95bfa3 = () => interopDefault(import('../client/pages/products/_slug/reviews.vue' /* webpackChunkName: "pages/products/_slug/reviews" */))
const _1f491a2b = () => interopDefault(import('../client/pages/facets/_slug/_facet/index.vue' /* webpackChunkName: "pages/facets/_slug/_facet/index" */))
const _054a5118 = () => interopDefault(import('../client/pages/supplier/_psCode/_.vue' /* webpackChunkName: "pages/supplier/_psCode/_" */))
const _34dc1299 = () => interopDefault(import('../client/pages/cat/_slug/_.vue' /* webpackChunkName: "pages/cat/_slug/_" */))
const _5876f617 = () => interopDefault(import('../client/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/box/cart",
    component: _1c6738ca,
    name: "box/cart"
  }, {
    path: "/quote/cart",
    component: _1c6738ca,
    name: "quote/cart"
  }, {
    path: "/sample/cart",
    component: _1c6738ca,
    name: "sample/cart"
  }, {
    path: "/proof/cart",
    component: _1c6738ca,
    name: "proof/cart"
  }, {
    path: "/boxcart",
    component: _1c6738ca,
    name: "boxcart"
  }, {
    path: "/quotecart",
    component: _1c6738ca,
    name: "quotecart"
  }, {
    path: "/samplecart",
    component: _1c6738ca,
    name: "samplecart"
  }, {
    path: "/proofcart",
    component: _1c6738ca,
    name: "proofcart"
  }, {
    path: "/products/no-minimum",
    component: _09f991d6,
    name: "products-no-minimum"
  }, {
    path: "/products/closeout",
    component: _6d256529,
    name: "products-closeout"
  }, {
    path: "/account",
    component: _62f5f7a8,
    name: "account"
  }, {
    path: "/cart",
    component: _1c6738ca,
    name: "cart"
  }, {
    path: "/cartitemsadded",
    component: _974df64a,
    name: "cartitemsadded"
  }, {
    path: "/changepassword",
    component: _07d61506,
    name: "changepassword"
  }, {
    path: "/customer-artwork-proof",
    component: _1f340ff7,
    name: "customer-artwork-proof"
  }, {
    path: "/designs",
    component: _6ae7ac20,
    name: "designs"
  }, {
    path: "/facets",
    component: _a5b831d2,
    name: "facets"
  }, {
    path: "/favorites",
    component: _cea9f95c,
    name: "favorites"
  }, {
    path: "/forgotpassword",
    component: _3819e48e,
    name: "forgotpassword"
  }, {
    path: "/health",
    component: _c0597f58,
    name: "health"
  }, {
    path: "/not-found",
    component: _e762c87a,
    name: "not-found"
  }, {
    path: "/proof",
    component: _6231adff,
    name: "proof"
  }, {
    path: "/proofitemsadded",
    component: _2100bb82,
    name: "proofitemsadded"
  }, {
    path: "/quoteitemsadded",
    component: _088f6912,
    name: "quoteitemsadded"
  }, {
    path: "/resetpassword",
    component: _5b9b8625,
    name: "resetpassword"
  }, {
    path: "/sampleitemsadded",
    component: _5e430336,
    name: "sampleitemsadded"
  }, {
    path: "/signin",
    component: _c53f74c6,
    name: "signin"
  }, {
    path: "/signout",
    component: _7cac04ec,
    name: "signout"
  }, {
    path: "/suppliers",
    component: _9afc55bc,
    name: "suppliers"
  }, {
    path: "/testy",
    component: _3ed9b322,
    name: "testy"
  }, {
    path: "/version",
    component: _0f8693e0,
    name: "version"
  }, {
    path: "/version-app",
    component: _38a36a67,
    name: "version-app"
  }, {
    path: "/cat/GeneralProductView",
    component: _d02c42c6,
    name: "cat-GeneralProductView"
  }, {
    path: "/orders/products",
    component: _0d254cee,
    name: "orders-products"
  }, {
    path: "/products/utils",
    component: _0d2acade,
    name: "products-utils"
  }, {
    path: "/sso/facebook/delete",
    component: _2a30e84c,
    name: "sso-facebook-delete"
  }, {
    path: "/review/order/:orderId?/product/:productId?",
    component: _02f39430,
    name: "review-order-orderId-product-productId"
  }, {
    path: "/products/search/*",
    component: _4518748a,
    name: "products-search-all"
  }, {
    path: "/products/no-minimum/*",
    component: _09f991d6,
    name: "products-no-minimum-all"
  }, {
    path: "/products/closeout/*",
    component: _6d256529,
    name: "products-closeout-all"
  }, {
    path: "/blog/cat/*",
    component: _4c9c999e,
    name: "blog-cat-all"
  }, {
    path: "/approval/:orderId",
    component: _26de8851,
    name: "approval-orderId"
  }, {
    path: "/artproof/:productSlug",
    component: _701768f8,
    name: "artproof-productSlug"
  }, {
    path: "/authors/:authorName",
    component: _14880149,
    name: "authors-authorName"
  }, {
    path: "/components/:componentId",
    component: _76708ecd,
    name: "components-componentId"
  }, {
    path: "/configureproduct/:productSlug",
    component: _4aa2a808,
    name: "configureproduct-productSlug"
  }, {
    path: "/customer-artwork-proof/:id",
    component: _85f3513c,
    name: "customer-artwork-proof-id"
  }, {
    path: "/designer/:productSlug",
    component: _6cbd7a1a,
    name: "designer-productSlug"
  }, {
    path: "/designs/:slug",
    component: _2f72f12b,
    name: "designs-slug"
  }, {
    path: "/facets/:slug",
    component: _e4c815dc,
    name: "facets-slug"
  }, {
    path: "/largequote/:productSlug",
    component: _510d47b8,
    name: "largequote-productSlug"
  }, {
    path: "/orders/:id",
    component: _6164cc0b,
    name: "orders-id"
  }, {
    path: "/payments/:orderId",
    component: _4b36d327,
    name: "payments-orderId"
  }, {
    path: "/presentation/:orderId",
    component: _d0baabcc,
    name: "presentation-orderId"
  }, {
    path: "/presentations/:id",
    component: _2d6d33ff,
    name: "presentations-id"
  }, {
    path: "/printinvoice/:orderId",
    component: _3f795394,
    name: "printinvoice-orderId"
  }, {
    path: "/products/:slug",
    component: _1062c64c,
    name: "products-slug"
  }, {
    path: "/proof/:id",
    component: _27149d2c,
    name: "proof-id"
  }, {
    path: "/quickquote/:productSlug",
    component: _2972a7d6,
    name: "quickquote-productSlug"
  }, {
    path: "/reorder/:id",
    component: _d98c563e,
    name: "reorder-id"
  }, {
    path: "/sample/:productSlug",
    component: _3b3e5c7b,
    name: "sample-productSlug"
  }, {
    path: "/sso/:service?",
    component: _19220a6e,
    name: "sso-service"
  }, {
    path: "/cat/:slug?/brand",
    component: _5ec028c1,
    name: "cat-slug-brand"
  }, {
    path: "/orders/:id?/track-shipment",
    component: _08a06794,
    name: "orders-id-track-shipment"
  }, {
    path: "/products/:slug?/reviews",
    component: _7f95bfa3,
    name: "products-slug-reviews"
  }, {
    path: "/facets/:slug/:facet",
    component: _1f491a2b,
    name: "facets-slug-facet"
  }, {
    path: "/supplier/:psCode?/*",
    component: _054a5118,
    name: "supplier-psCode-all"
  }, {
    path: "/cat/:slug?/*",
    component: _34dc1299,
    name: "cat-slug-all"
  }, {
    path: "/*",
    component: _5876f617,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
