import { CLEAR } from './constants';

const Cookie = process.client ? require('js-cookie') : undefined;

export const state = () => ({
  favorites: [],
  loggedInModal: {
    productsAdded: false,
    onFavoritesPage: false,
  },
});

export const getters = {
  customerFavorites: (state) => state.favorites,
  loggedInModal: (state) => state.loggedInModal,
};

export const actions = {
  clear({ commit }) {
    commit(CLEAR);
  },
  async toggleFavorite({ commit, state, rootState }, product) {
    const { slug, _id: productId } = product;
    const { _id: storeId } = rootState.store;
    const logginedUser = rootState.auth.user;

    const isFavorit = !!state.favorites.find((fav) => fav === slug);
    if (isFavorit) {
      if (logginedUser) {
        await this.$axios.delete(`favorites/${storeId}/${logginedUser._id}/${productId}`);
      }
      commit('REMOVE_FAVORITE', slug);
    } else {
      if (logginedUser) {
        const data = { customerId: logginedUser._id, productId };
        await this.$axios.post(`/favorites/${storeId}`, data);
      }
      commit('ADD_FAVORITE', slug);
    }

    if (!logginedUser) {
      Cookie.set('favorites', JSON.stringify(state.favorites));
    }
  },
  async getFavorites({ commit, rootState }) {
    const logginedUser = rootState.auth.user;
    const slugs = JSON.parse(Cookie.get('favorites') || '[]');
    let customerSlugs = [];

    if (logginedUser) {
      const { _id: storeId } = rootState.store;
      try {
        const favorites = (await this.$axios.get(`favorites/${storeId}/${logginedUser._id}/slugs`)).data;
        customerSlugs = favorites
          .map(({ productId }) => productId && productId.slug)
          .filter((slug) => !!slug && !slugs.includes(slug));
      } catch (e) {}
    }

    commit('SET_FAVORITES', [...slugs, ...customerSlugs]);
  },
};

export const mutations = {
  [CLEAR](state) {
    Object.assign(state, {
      favorites: [],
      loggedInModal: {
        productsAdded: false,
        onFavoritesPage: false,
      },
    });
  },
  SET_FAVORITES(state, favorites) {
    state.favorites = favorites;
  },
  PRODUCTS_ADDED(state) {
    state.loggedInModal.productsAdded = !state.loggedInModal.productsAdded;
  },
  ON_FAVORITES_PAGE(state) {
    state.loggedInModal.onFavoritesPage = !state.loggedInModal.onFavoritesPage;
  },
  ADD_FAVORITE(state, favorite) {
    state.favorites = [...state.favorites, favorite];
  },
  REMOVE_FAVORITE(state, favorite) {
    state.favorites = state.favorites.filter((s) => s !== favorite);
  },
};
