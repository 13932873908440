import { unitPriceCalc } from '../plugins/productPrices';

export const CONFIGURATION_TYPE = {
  Blank: 'Blank',
  Decorated: 'Decorated',
  Sample: 'Sample',
  Box: 'Box',
};

export const checkSupplierPriceConfigurationType = (productData) => {
  if (productData.supplierId?.configurationTypeToHide?.length) {
    for (let i = 0; i < productData.parts.length; i++) {
      productData.parts[i].pricing = productData.parts[i].pricing.filter(
        (e) => !productData.supplierId?.configurationTypeToHide.includes(e.configurationType)
      );
    }
  }
};
export const feedSamplePrices = (parts, product, store) => {
  if (product?.supplierId?.configurationTypeToHide?.includes(CONFIGURATION_TYPE.Sample)) {
    return;
  }
  const sampleMargin = product && product.supplierId && product.supplierId.sampleMargin;
  parts.forEach((part) => {
    if (
      store &&
      store.apparelSizes?.length &&
      part.ApparelSize &&
      part.ApparelSize.label_size &&
      !part.ApparelSize.code
    ) {
      part.ApparelSize.code = store.apparelSizes
        .map((e) => e.toUpperCase())
        .indexOf(part.ApparelSize.label_size.toUpperCase());
    }
    if (!part.pricing) {
      part.pricing = [];
    }
    if (
      part.pricing.filter((e) => e.minQuantity && (e.price || e.unitPrice)).length &&
      !part.pricing.find((e) => e.configurationType === CONFIGURATION_TYPE.Sample)
    ) {
      let price = part.pricing
        .filter((e) => e.minQuantity && e.configurationType === CONFIGURATION_TYPE.Blank)
        .sort((a, b) => a.unitPrice - b.unitPrice)[0] ||
        part.pricing
          .filter((e) => e.minQuantity && e.configurationType === CONFIGURATION_TYPE.Decorated)
          .sort((a, b) => a.unitPrice - b.unitPrice)[0] ||
        part.pricing.filter((e) => e.minQuantity).sort((a, b) => a.unitPrice - b.unitPrice)[0] || { minQuantity: 1 };
      part.pricing.push({
        ...price,
        ...(sampleMargin && {
          unitPrice: unitPriceCalc({ ...price, margin: sampleMargin }),
          margin: sampleMargin,
        }),
        configurationType: CONFIGURATION_TYPE.Sample,
        minQuantity: 1,
      });
    }
  });
};
export const checkLocationDecorationPartPrices = (product, parts = []) => {
  if (product.selectedDecoration) {
    const _id = `${product.selectedDecoration.location?._id || '-'}_${product.selectedDecoration.name}`;
    const _parts = [...product.parts, ...parts];
    _parts.forEach((part) => {
      if (part.locationDecorationPricing && part.locationDecorationPricing[_id]) {
        part.pricing = part.pricing.filter((e) => !e.temp);
        const pricing = part.locationDecorationPricing[_id].map((e) => ({ ...e, temp: 1 }));
        if (pricing.length) {
          part.pricing = pricing;
        }
      }
    });
  }
};
