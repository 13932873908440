export default function() {
  if (process.client) {
    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get('utm_source');
    const utmMedium = urlParams.get('utm_medium');
    const utmCampaign = urlParams.get('utm_campaign');
    if (utmSource || utmMedium || utmCampaign) {
      const Cookie = require('js-cookie');
      const inThirtyDays = new Date(new Date().getTime() + 30 * 24 * 3600 * 1000);
      Cookie.set('utm_source', utmSource, { expires: inThirtyDays });
      Cookie.set('utm_medium', utmMedium, { expires: inThirtyDays });
      Cookie.set('utm_campaign', utmCampaign, { expires: inThirtyDays });
    }
  }
}
