import { encodeOpts as paramsSerializer } from '@/utils/encode-opts';

const facetsApiFactory = (axios) => ({
  getFacets: async (params = {}) => {
    return (await axios.get(`/facets`, { params, paramsSerializer })).data;
  },
  facetsSearch(query, esSearch = false, limit = 5) {
    const params = {
      ...query,
      esSearch,
      inActive: false,
      populateFacetGroup: true,
      withProducts: true,
      limit,
    };
    return this.getFacets(params);
  },
  async facetsCategoriesSearch(query, esSearch = false, limit = 5) {
    const params = {
      ...query,
      inActive: false,
      limit,
    };
    return (await axios.get(`/facets-categories-pages`, { params, paramsSerializer })).data;
  },
});

export { facetsApiFactory };
