import Vue from 'vue';
import { mapGetters } from 'vuex';
import { eventBus } from '@/assets/js/bus';
import mPowerMoment from '@/utils/mPowerMoment';
import { loadScripts } from '@/utils/scripts';

String.prototype.removeSpecialChars = function() {
  let str = this;
  str
    .replace(/[\s\.\,\%a-zA-Z0-9]/gi, '')
    .split('')
    .filter((el) => el)
    .forEach((specialCharToRemove) => {
      str = str.replace(specialCharToRemove, ' ');
    });
  return str;
};

const toastScripts = [
  { type: 'link', src: 'https://cdn.jsdelivr.net/npm/toastify-js/src/toastify.min.css' },
  { type: 'script', src: 'https://cdn.jsdelivr.net/npm/toastify-js' },
];
const introScript = [
  { type: 'link', src: 'https://cdnjs.cloudflare.com/ajax/libs/intro.js/7.0.1/introjs.min.css' },
  { type: 'script', src: 'https://cdnjs.cloudflare.com/ajax/libs/intro.js/7.0.1/intro.min.js' },
];

const toastify = async (text, { className }) => {
  await loadScripts(toastScripts);
  const isE2E = process.env.STORE_URL === 'e2e' || process.env.IS_E2E;
  process.client &&
    window
      .Toastify({
        text,
        duration: isE2E ? 500 : 5000,
        gravity: 'top',
        position: 'center',
        newWindow: true,
        close: true,
        className: className || 'info',
        style: {
          background: 'none',
        },
      })
      .showToast();
};

const sleep = (t = 1000) => new Promise((resolve) => setTimeout(resolve, t));
Vue.mixin({
  install: () => {
    Vue.prototype.$validator = {};
    Vue.prototype.$errors = {
      any: () => '',
      first: () => '',
    };
  },
  data() {
    return {
      // errors: {
      //   any: (e) => '',
      //   first: (e) => '',
      // },
      mPowerIntroJs: async () => {
        await loadScripts(introScript);
        return process.client && new window.introJs();
      },
      mPowerMoment: mPowerMoment.bind(this),
      mPowerToastItem: {
        info: toastify,
        success: toastify,
        error: toastify,
      },
    };
  },
  filters: {
    capitalize(value) {
      if (!value) return '';
      return _.startCase(value);
    },
    removeNA(value) {
      if (!value) return '';
      //return value.toLowercase().replace('n/a', '');
      return '';
    },
  },

  computed: {
    ...mapGetters({
      storeInformation: 'storeInformation',
      loggedInUser: 'auth/loggedInUser',
    }),

    noQuery() {
      return (
        Object.keys(this.$route.query).length === 0 &&
        (this.$route.fullPath.split('/').length <= 3 ||
          (this.$route.fullPath.match('/products/search') && this.$route.fullPath.split('/').length <= 4))
      );
    },
    formIsInvalid() {
      return this.errors?.any() || this.errors?.items?.length > 0;
    },
    formIsInvalidMessage() {
      return this.errors?.any() || (this.errors?.items?.length && this.errors.items[0].msg);
    },
  },
  methods: {
    async is_DevEnv() {
      if (!process.client) {
        return false;
      }
      const IS_DEV_ENV = window.location.origin.match(/localhost|127.0.0.1/gi);
      if (IS_DEV_ENV) {
        await sleep(1500);
      }
      return 1;
    },
    validateState(ref, validState = null) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        if (!this.errors.has(ref)) {
          return validState;
        } else {
          return false;
        }
      }
      return null;
    },
    storeProductDiscountMain(product) {
      return this.storeProductDiscount && this.storeProductDiscount(product, this.storeInformation, this.loggedInUser);
    },
    storeProductFundraisingAmountMain(price) {
      return this.storeProductFundraisingAmount && this.storeProductFundraisingAmount(price, this.storeInformation);
    },
    showFavoritesModal() {
      eventBus.$emit('showFavoritesModal');
    },
    hideFavoritesModal() {
      eventBus.$emit('hideFavoritesModal');
    },
    hideFavoritesModalWithCookie() {
      eventBus.$emit('hideFavoritesModalWithCookie');
    },
    setCoordinates(address) {
      if (!address) return;
      return new Promise((resolve, reject) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode(
          { address: `${address.street1} ${address.city} ${address.state} ${address.zip}` },
          (results, status) => {
            if (status === 'OK' && results.length) {
              resolve(
                Object.assign(address, {
                  lat: results[0].geometry.location.lat(),
                  lng: results[0].geometry.location.lng(),
                })
              );
            } else {
              this.notificateError('Address is wrong, try again');
              reject(new Error('Address is wrong'));
            }
          }
        );
      });
    },
    notificateError(message) {
      this.mPowerToastItem.error(message, { position: 'top-center', className: 'bg-danger' });
    },
    notificateSuccess(message) {
      this.mPowerToastItem.success(message, { position: 'top-center', className: 'bg-success' });
    },
    notificateWarning(message) {
      this.mPowerToastItem.info(message, { position: 'top-center', className: 'bg-warning' });
    },
    isNotNuxtLink(link) {
      try {
        return (
          link.includes('https://') || link.includes('http://') || link.includes('tel:') || link.includes('mailto:')
        );
      } catch (e) {}
    },
    storeRoleExistOnCustomer(link) {
      if (!link.storeRoles || link.storeRoles === '') {
        return true;
      }

      if (Array.isArray(link.storeRoles)) {
        return link.storeRoles.some((role) => this.loggedInUser?.roles.some((r) => r._id === role._id));
      }

      return false;
    },
  },
});
