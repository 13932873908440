import Vue from 'vue';
import DateOnly from 'dateonly';

Vue.filter('capitalize', function(str) {
  if (!str) return '';
  str = str.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
});

Vue.filter('title', function(str) {
  if (!str) return '';
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0)?.toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
});
Vue.filter('default_val', function(value) {
  if (typeof value === 'undefined' || typeof value === 'boolean') {
    return '-';
  }
  if (value === 0) {
    return value.toString();
  }
  return value;
});

Vue.filter('uppercase', function(value) {
  return value.toUpperCase();
});

Vue.filter('nozeroes', function(str) {
  if (!str) return '';
  return parseFloat(str);
});

Vue.filter('deCamelCase', function(str) {
  if (!str) return '';
  str = str.toString();
  const toUppercase = (str) => str.toUpperCase();
  return str.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase);
});

Vue.filter('fileSize', function(num = 0) {
  var exponent;
  var unit;
  var neg = num < 0;
  var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return (neg ? '-' : '') + num + ' B';
  }

  exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
  num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
  unit = units[exponent];

  return (neg ? '-' : '') + num + ' ' + unit;
});

Vue.filter('startCase', function(value) {
  let wordsArray = value.toLowerCase().split(' ');
  let capsArray = [];

  wordsArray.forEach((word) => {
    capsArray.push((word[0]?.toUpperCase() || '') + word.slice(1));
  });

  return capsArray.join(' ');
});
Vue.filter('date', function(value) {
  if (!value) return '';

  return new DateOnly(value).toDate();
});

// Vue.filter('currency', function(value) {
//   if (isNaN(parseFloat(value))) return '';
//   return '$' + parseFloat(value).toFixed(2);
// });
