const orderBy = require('lodash/orderBy');
const groupBy = require('lodash/groupBy');
const uniqBy = require('lodash/uniqBy');
const uniq = require('lodash/uniq');
const cloneDeep = require('lodash/cloneDeep');
const difference = require('lodash/difference');
const debounce = require('lodash/debounce');
const startCase = require('lodash/startCase');
const isEmpty = require('lodash/isEmpty');
const defaults = require('lodash/defaults');
const pick = require('lodash/pick');
const get = require('lodash/get');
const set = require('lodash/set');
const map = require('lodash/map');
const every = require('lodash/every');
const isArray = require('lodash/isArray');
const forEach = require('lodash/forEach');
const uniqWith = require('lodash/uniqWith');
const isEqual = require('lodash/isEqual');

(function() {
  /** Used as a safe reference for `undefined` in pre-ES5 environments. */
  let undefined;

  /** Built-in method references without a dependency on `root`. */
  const freeParseFloat = parseFloat,
    freeParseInt = parseInt;

  /** Detect free variable `global` from Node.js. */
  const freeGlobal = typeof global == 'object' && global && global.Object === Object && global;

  /** Detect free variable `self`. */
  const freeSelf = typeof self == 'object' && self && self.Object === Object && self;

  /** Used as a reference to the global object. */
  const root = freeGlobal || freeSelf || Function('return this')();

  /** Detect free variable `exports`. */
  const freeExports = typeof exports == 'object' && exports && !exports.nodeType && exports;

  /** Detect free variable `module`. */
  const freeModule = freeExports && typeof module == 'object' && module && !module.nodeType && module;

  /** Detect the popular CommonJS extension `module.exports`. */
  const moduleExports = freeModule && freeModule.exports === freeExports;

  /** Detect free variable `process` from Node.js. */
  const freeProcess = moduleExports && freeGlobal.process;

  const runInContext = function runInContext(context) {
    context = context == null ? root : defaults(root.Object(), context, pick(root, contextProps));
    function lodash(value) {
      if (isObjectLike(value) && !isArray(value) && !(value instanceof LazyWrapper)) {
        if (value instanceof LodashWrapper) {
          return value;
        }
        if (hasOwnProperty.call(value, '__wrapped__')) {
          return wrapperClone(value);
        }
      }
      return new LodashWrapper(value);
    }

    function LodashWrapper(value, chainAll) {
      this.__wrapped__ = value;
      this.__actions__ = [];
      this.__chain__ = !!chainAll;
      this.__index__ = 0;
      this.__values__ = undefined;
    }

    lodash.templateSettings = {
      imports: {
        _: lodash,
      },
    };

    // Ensure wrappers are instances of `baseLodash`.
    lodash.prototype.constructor = lodash;

    LodashWrapper.prototype.constructor = LodashWrapper;

    lodash.uniqWith = uniqWith;
    lodash.isEqual = isEqual;
    lodash.get = get;
    lodash.set = set;
    lodash.map = map;
    lodash.every = every;
    lodash.isArray = isArray;
    lodash.forEach = forEach;
    lodash.orderBy = orderBy;
    lodash.isEmpty = isEmpty;
    lodash.groupBy = groupBy;
    lodash.uniqBy = uniqBy;
    lodash.uniq = uniq;
    lodash.cloneDeep = cloneDeep;
    lodash.difference = difference;
    lodash.debounce = debounce;
    lodash.startCase = startCase;

    return lodash;
  };

  /*--------------------------------------------------------------------------*/

  // Export lodash.
  const _ = runInContext();

  // Some AMD build optimizers, like r.js, check for condition patterns like:
  if (typeof define == 'function' && typeof define.amd == 'object' && define.amd) {
    // Expose Lodash on the global object to prevent errors when Lodash is
    // loaded by a script tag in the presence of an AMD loader.
    // See http://requirejs.org/docs/errors.html#mismatch for more details.
    // Use `_.noConflict` to remove Lodash from the global object.
    root._ = _;

    // Define as an anonymous module so, through path mapping, it can be
    // referenced as the "underscore" module.
    define(function() {
      return _;
    });
  }
  // Check for `exports` after `define` in case a build optimizer adds it.
  else if (freeModule) {
    // Export for Node.js.
    (freeModule.exports = _)._ = _;
    // Export for CommonJS support.
    freeExports._ = _;
  } else {
    // Export to the global object.
    root._ = _;
  }
}.call(this));
